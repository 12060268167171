import { Routes, Route } from '@angular/router';
import { AuthorizeGuard } from '@core/services';
import { PrivateShellComponent } from './private-shell.component';

/**
 * Provides helper methods to create routes.
 */
export class PrivateShell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: PrivateShellComponent,
      children: routes,
      canActivate: [AuthorizeGuard]
    };
  }
}
