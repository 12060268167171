<div #modalWindow class="modal-dialog modal-dialog-centered modal-sm">
  <div class="modal-content" [class]="inputClass">
    <div *ngIf="popUpDetails?.popUpMessage" class="modal-message">
      <app-svg-icon [icon]="messageIcon"></app-svg-icon>
      <span>{{ (popUpDetails?.popUpMessage || '') | translate }}</span>
    </div>
    <div class="d-flex content">
      <div class="text-wrapper">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmation-message">{{ popUpDetails?.popUpTitle }}</h5>
        </div>
        <div class="modal-body" [innerHtml]="popUpDetails?.popUpContent || '' | translate"></div>
      </div>
      <div class="w-100 image-container">
        <div class="image"></div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        #closeModal
        data-dismiss="modal"
        class="btn basic text-uppercase border-0 d-inline-flex justify-content-center align-items-center"
        (click)="popUpDetails?.path ? next() : false"
        data-bs-dismiss="modal"
      >
        <span class="text">{{ (popUpDetails?.popUpButton || '') | translate }}</span>
      </button>
    </div>
  </div>
</div>
