<div #modalWindow class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="d-flex content">
      <button
        type="button"
        type="button"
        class="close-btn"
        #closeModal
        data-dismiss="modal"
        data-bs-dismiss="modal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="image-container">
        <img class="image" alt="myVVid-logo" src="/assets/icons/svg/undraw_bug_fixing.svg" />
      </div>
      <div class="text-wrapper">
        <div class="modal-body">
          <h4 class="modal-title mb-3" id="notification-message">
            {{ "NOTIFICATION_MESSAGE.HEADING" | translate }}
          </h4>
          <p>{{ "NOTIFICATION_MESSAGE.TEXT_CONTENT_1" | translate }}</p>
          <p>{{ "NOTIFICATION_MESSAGE.TEXT_CONTENT_2" | translate }}</p>
          <p>
            {{ "NOTIFICATION_MESSAGE.TEXT_CONTENT_3" | translate }}
            <a class="support-email" href="mailto:support@hivve.tech">support@hivve.tech</a>.
          </p>
          <p>{{ "NOTIFICATION_MESSAGE.TEXT_CONTENT_4" | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
