import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { LoaderService } from '@core/services';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() id?: string;
  @Input() classStyle: string = '';
  @Input() icon: string = '';
  @Input() type: string = 'button';
  @Input() text: string = '';
  @Input() isDisabled = false;
  @Input() loadingText: string = '';
  @Input() overrideLoading!: boolean;

  isLoading: boolean = false;

  constructor(private loader: LoaderService) {}

  ngOnInit() {
    if (this.overrideLoading === undefined) {
      this.loader.delay = 5000; // in milliseconds (5 seconds)
      this.loader.loading$.subscribe(value => (this.isLoading = value));
    }
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let change: SimpleChange = changes['overrideLoading'];

    if (change) {
      this.isLoading = change.currentValue || false;
    }
  }
}
