import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-shell',
  templateUrl: './public-shell.component.html',
  styleUrls: ['./public-shell.component.scss']
})
export class PublicShellComponent implements OnInit {
  isNativeLogIn = false;
  hasSignInError = false;
  show = false;
  appHeaderMessage = '';
  headerMessageSignIn = "You're almost there!";
  subHeaderMessage = 'Sign in to your account';
  queryParams!: string;

  get headerMessage(): string {
    return this.isNativeLogIn ? this.headerMessageSignIn : this.appHeaderMessage;
  }

  constructor() {}
  ngOnInit(): void {}

  showMessage(message: string): void {
    this.appHeaderMessage = message;
  }
}
