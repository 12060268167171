import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { APP_ROUTES } from '@core/constants';

@Injectable()
export class ErrorPageGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const navigation = this.router.getCurrentNavigation();

    if (navigation && navigation.extras) {
      const state = navigation.extras.state as { error: string };

      if (state?.error) {
        return true;
      }
    }

    this.router.navigate([APP_ROUTES.BASE]);
    return false;
  }
}
