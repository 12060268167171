<nav class="{{isCollapsed ? 'compacted' : 'expanded' }} pt-3 pb-5">
  <div class="sidebar-close">
    <a type="button" (click)="onClose()">
      <app-svg-icon class="svg-icon" [icon]="Icon.CLOSE"></app-svg-icon>
    </a>
  </div>
  <div class="brand text-center px-3">
    <a href="/">
      <img
        alt="myVVid-logo"
        class="img-fluid"
        src="/assets/brand/myvvid-logo-portrait-reversed.svg"
      />
      <img alt="myVVid-logo" class="img-fluid" src="/assets/brand/logo-reversed.svg" />
    </a>
  </div>
  <div class="profile">
    <div class="profile-dp">
      <app-avatar class="avatar" styleClass="avatar-size" [avatar]="avatar">
        <div indicator class="avatar-status"></div>
      </app-avatar>
    </div>
    <div class="profile-desc py-3">
      <h5>{{ fullName }}</h5>
      <p>{{ jobTitle }}</p>
    </div>
  </div>
  <div *ngIf="!environment.production">
    <div class="w-100 divider-2"></div>
    <div class="w-100 divider-1"></div>
    <ul class="nav flex-column">
      <li class="nav-item">
        <app-language-selector></app-language-selector>
      </li>
    </ul>
  </div>

  <div class="w-100 divider-2"></div>
  <div class="w-100 divider-1"></div>

  <ul class="nav nav-menu flex-column">
    <ng-container *ngFor="let menu of navigationMenu">
      <li
        *ngIf="!menu.hidden"
        class="nav-item"
        routerLinkActive="active"
        [pTooltip]="menu.i18n | translate"
        tooltipStyleClass="tooltip {{ isCollapsed ? 'd-inline-block' : 'd-none'}}"
      >
        <a [routerLink]="[menu.route]" class="nav-link">
          <app-svg-icon
            class="svg-icon d-inline-block"
            styleClass="d-inline-block"
            [icon]="menu.icon"
          ></app-svg-icon>
          <span class="nav-link-text">{{ menu.i18n | translate }}</span>
        </a>
      </li>
    </ng-container>
  </ul>
</nav>
