import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from 'src/app/app.config';

export interface AppLogoDetails {
  appLogoUrl: string;
  appHeaderMessage: string;
}
@Component({
  selector: 'app-left-side',
  templateUrl: './left-side.component.html',
  styleUrls: ['./left-side.component.scss']
})
export class LeftSideComponent implements OnInit {
  clientLogo!: string;
  clientName!: string;

  constructor(private config: AppConfig, private route: ActivatedRoute) {}

  ngOnInit(): void {
    let client;
    const clientId = this.route.snapshot.queryParams['client_id'];

    if (clientId) {
      client = this.config.getClient(clientId);
    }

    if (client) {
      this.clientName = client.client_name;
      this.clientLogo = `/assets/apps/${client.client_logo_alt}`;
    }
  }
}
