import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from '@shared/components/svg-sprite/svg-icon/svg-icon.component';
import { SvgSpriteDefComponent } from '@shared/components/svg-sprite/svg-sprite-def/svg-sprite-def.component';

@NgModule({
  declarations: [SvgSpriteDefComponent, SvgIconComponent],
  imports: [CommonModule],
  exports: [SvgSpriteDefComponent, SvgIconComponent]
})
export class SvgSpriteModule {}
