import { Directive, HostListener, Optional, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appInputTrim]'
})
export class TrimInputDirective {
  constructor(private el: ElementRef, @Optional() private ngControl: NgControl) {}

  @HostListener('blur') onBlur() {
    if (typeof this.ngControl.control?.value === 'string') {
      this.ngControl.control?.setValue(this.el.nativeElement.value.trim());
    }
  }
}
