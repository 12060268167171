import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: 'img[appImageFallback]'
})
export class ImageFallbackDirective {
  @Input() fallbackSrc: string | undefined;
  @Output() hasErrorEvent = new EventEmitter<boolean>();

  private image: HTMLImageElement;

  constructor(private el: ElementRef) {
    this.image = this.el.nativeElement;
  }

  @HostListener('error') onError(): void {
    if (this.fallbackSrc) {
      this.image.src = this.fallbackSrc;
      this.hasErrorEvent.emit(false);
      return;
    }

    this.image.style.display = 'none';
    this.hasErrorEvent.emit(true);
  }
}
