<ng-container
  appFullContent
  bodyClass="d-flex flex-column h-100 bg-maintenance"
  rootClass="d-flex flex-column h-100"
>
  <div class="maintenance-wrapper">
    <div class="maintenance-wrapper-content">
      <div class="d-flex d-xl-none justify-content-center">
        <div class="myvvid-logo"></div>
      </div>
      <div class="d-block d-xl-flex justify-content-center align-items-center">
        <div class="text">
          <h1 class="heading text-uppercase text-bold mb-5">
            {{ "MAINTENANCE.UNDER_MAINTENANCE" | translate }}
          </h1>
          <div class="text-content">
            <p class="m-0">{{ "MAINTENANCE.MAINTENANCE_CONTENT_1" | translate }}</p>
            <p>{{ "MAINTENANCE.MAINTENANCE_CONTENT_2" | translate }}</p>
            <p class="mb-0">{{ "MAINTENANCE.MAINTENANCE_CONTENT_3" | translate }}</p>
            <p>{{ "MAINTENANCE.MAINTENANCE_CONTENT_4" | translate }}</p>
          </div>
        </div>
        <div class="image-wrapper">
          <img
            class="maintenance-image"
            alt="myVVid-logo"
            src="/assets/images/under_maintenance.png"
          />
        </div>
      </div>
      <div class="d-none d-xl-flex justify-content-end">
        <div class="myvvid-logo"></div>
      </div>
    </div>
  </div>
</ng-container>
