import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COUNTRY_CODES, DEFAULT_PHONE_CODE, PHONE_NUMBER_PATTERN } from '@core/constants';

@Component({
  selector: 'app-change-number',
  templateUrl: './change-number.component.html',
  styleUrls: ['./change-number.component.scss']
})
export class ChangeNumberComponent implements OnInit {
  @Input() missingMobileNumber = false;
  @Output() updateMobileNumber = new EventEmitter<string>();

  readonly defaultPhoneCode = DEFAULT_PHONE_CODE;
  readonly countryCodes = COUNTRY_CODES;

  mobileNumberRegionCode: string | undefined;
  selectedCountryCode!: string;
  selectedCountry!: string;
  mobileNumberExists = false;

  updateMobileNumberForm: FormGroup;
  mobileNumberCodeControl: FormControl;
  mobileNumberControl: FormControl;

  get headerText(): string {
    return this.missingMobileNumber ? 'Add a cell phone number' : 'Change your number';
  }

  get subheaderText(): string {
    return this.missingMobileNumber
      ? `We don't seem to have a number for you.</br>
        Please add your number below to secure your account.</br>
        We do not share this information.`
      : 'Enter your new phone number below';
  }

  get mobileNumber(): string {
    let number = this.updateMobileNumberForm.controls['mobileNumber'].value;
    let numberCode = this.updateMobileNumberForm.controls['mobileNumberCode'].value;

    if (number.charAt(0) === '0') {
      number = number.substring(1);
    }

    const mobileNumber = numberCode + number;

    return mobileNumber;
  }

  get disableSubmitBtn(): boolean {
    return (
      !this.mobileNumberCodeControl.value ||
      !this.mobileNumberControl.value ||
      this.mobileNumberControl.errors?.['pattern']
    );
  }

  constructor(private fb: FormBuilder) {
    this.updateMobileNumberForm = this.fb.group({
      mobileNumberCode: (this.mobileNumberCodeControl = this.fb.control(
        this.defaultPhoneCode.phoneCode,
        [Validators.required]
      )),
      mobileNumber: (this.mobileNumberControl = this.fb.control(null, [
        Validators.required,
        Validators.pattern(PHONE_NUMBER_PATTERN)
      ]))
    });
  }

  ngOnInit(): void {
    this.mobileNumberControl.valueChanges.subscribe(() => {
      this.mobileNumberExists = false;
    });
  }

  onUpdateMobileNumber(): void {
    this.updateMobileNumber.emit(this.mobileNumber);
  }

  onChangeMobileCode(phoneCodeKey: string): void {
    this.mobileNumberRegionCode = phoneCodeKey;

    if (!phoneCodeKey) {
      this.mobileNumberCodeControl.setValue(null);
      return;
    }

    const code = this.countryCodes.find(
      ({ code }) => code === this.mobileNumberRegionCode
    )?.phoneCode;

    this.mobileNumberCodeControl.setValue(code);
  }
}
