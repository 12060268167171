import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutoScroll]',
  exportAs: 'appAutoScroll'
})
export class AutoScrollDirective {
  target!: HTMLElement | null;

  constructor() {}

  @HostListener('click', ['$event'])
  onClick(event: any) {
    const element: HTMLElement = event.target || event.srcElement;

    let elId;
    if (element) {
      elId = element.getAttribute('href')?.replace('#', '');
    }

    if (elId) {
      this.target = document.getElementById(elId);
    }

    setTimeout(() => {
      if (this.target) {
        this.target.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);
  }
}
