<div class="toast-wrapper d-flex flex-column">
  <div
    *ngFor="let toast of value"
    aria-live="polite"
    class="toast toast-{{ toast.severity }} text-center bg-white"
  >
    <i
      *ngIf="!toast.icon"
      class="icon"
      [class.icon-toast-success]="toast.severity === 'success'"
      [class.icon-toast-error]="toast.severity === 'error'"
      [class.icon-toast-warning]="toast.severity === 'warning'"
    ></i>
    <i *ngIf="toast.icon" class="icon icon-{{ toast.icon }}"></i>
    <span class="text">{{ toast.text }}</span>
  </div>
</div>
