<div
  class="new-password-error-container d-flex flex-row justify-content-center align-items-center"
  [class.weak]="score === 0 || score === 1"
  [class.moderate]="score === 2 || score === 3"
  [class.strong]="score === 4"
>
  <span *ngIf="score === 0 || score === 1">{{ "PROFILE.CHANGE_PASSWORD.WEAK" | translate }}</span>
  <span *ngIf="score === 2 || score === 3"
    >{{ "PROFILE.CHANGE_PASSWORD.MODERATE" | translate }}</span
  >
  <span *ngIf="score === 4">{{ "PROFILE.CHANGE_PASSWORD.STRONG" | translate }}</span>
</div>
