import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appTogglePassword]'
})
export class TogglePasswordDirective {
  private shown = false;
  private shownClass = 'icon icon-invisible';
  private hiddenClass = 'icon icon-visible';

  constructor(private el: ElementRef) {
    const parent = this.el.nativeElement.parentNode;
    const wrapper = document.createElement('div');
    const icon = document.createElement('div');

    wrapper.className = 'password-lock-wrapper';
    icon.className = this.hiddenClass;
    icon.addEventListener('click', () => {
      this.toggle(icon);
    });
    wrapper.appendChild(this.el.nativeElement);
    wrapper.appendChild(icon);
    parent.appendChild(wrapper);
  }

  private toggle(icon: HTMLElement): void {
    this.shown = !this.shown;

    if (this.shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      icon.className = this.shownClass;
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      icon.className = this.hiddenClass;
    }
  }
}
