<ng-container [formGroup]="editorForm">
  <div class="editor-wrapper">
    <div class="header">{{ header }}</div>
    <p-editor
      formControlName="descriptionControl"
      [placeholder]="placeholder"
      [style]="{'height':'320px'}"
      [ngClass]="{
        'error-validation-field': descriptionFormControl.errors?.['badWords']
      }"
      (onTextChange)="handleTextChange($event)"
      #descriptionEditor
    >
    </p-editor>
    <p class="error-message mt-3 mb-0" *ngIf="descriptionFormControl.errors?.['badWords']">
      {{ "PROFILE.PROFANITY_MESSAGE" | translate }}
    </p>
  </div>
</ng-container>
