import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../loader.service';

const NO_PENDING_REQUEST = 0;
const MIN_PENDING_REQUEST = 1;

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private _pendingRequests = 0;
  private _pendingRequestStatus = new ReplaySubject<boolean>();

  get pendingRequests(): number {
    return this._pendingRequests;
  }

  get pendingRequestStatus$(): Observable<boolean> {
    return this._pendingRequestStatus;
  }

  constructor(private loader: LoaderService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this._pendingRequests++;

    if (this._pendingRequests === MIN_PENDING_REQUEST) {
      this._pendingRequestStatus.next(true);
      this.loader.show();
    }

    return next.handle(req).pipe(
      finalize(() => {
        this._pendingRequests--;

        if (this._pendingRequests === NO_PENDING_REQUEST) {
          this._pendingRequestStatus.next(false);
          this.loader.hide();
        }
      })
    );
  }
}
