export const APP_ROUTES = {
  BASE: '',
  AUTOREDIRECT: 'autoredirect',
  DASHBOARD: 'dashboard',
  PROFILE: 'my-profile',
  PREFERENCES: 'my-profile/preferences',
  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password',
  CHANGE_PASSWORD: 'change-password',
  ERROR: 'error',
  CONFIRM: 'account-confirm',
  CREATE_ACCOUNT: 'create-account'
};

export const ERROR_MESSAGES = {
  LOGIN_ERROR:
    'The email or password you entered is invalid. Please come back and try again later.',
  SYSTEM_ERROR: 'There was an unexpected error. Please try again.',
  NO_SSO_ERROR:
    'The email/username or password you entered is invalid. Please also make sure you have selected the correct sign in option for your account type.'
};

export const POP_UP_DETAILS = {
  SEND_CODE_POP_UP_TITLE: 'Confirmation code was sent',
  SEND_CODE_POP_UP_CONTENT:
    'Please check your mobile or email for your confirmation code. Please make sure to check your spam folder.',
  SEND_CODE_POP_UP_BUTTON: 'Next',
  PASSWORD_CHANGE_SUCCESS_POP_UP_MESSAGE: 'You’ve Changed Your Password',
  PASSWORD_CHANGE_SUCCESS_POP_UP_TITLE: 'Now you can sign in to your account',
  PASSWORD_CHANGE_SUCCESS_POP_UP_CONTENT:
    'Your password was successfully changed! You will be automatically redirected to the sign in page in <span class="text-bold">5 seconds.</span>',
  PASSWORD_CHANGE_SUCCESS_POP_UP_BUTTON: 'SIGN IN',
  USER_PASSWORD_CHANGE_SUCCESS_POP_UP_CONTENT: 'You have successfully changed your password!',
  USER_PASSWORD_CHANGE_SUCCESS_POP_UP_BUTTON: 'OK'
};

export const REDIRECT_TIMEOUT_MILLISECONDS = 5000;

export const DEFAULT_PHONE_CODE = { countryCode: 'US', phoneCode: '+1' };

export const DEFAULT_PROFILE_BANNER_URL = '/assets/icons/svg/profile-banner.svg';

export const UNSPLASH_API_URL = {
  PHOTOS: 'https://api.unsplash.com/photos',
  SEARCH_PHOTOS: 'https://api.unsplash.com/search/photos'
};
