import { Component, Input } from '@angular/core';
import { SdgEnum } from '@core/enums/sdg.enums';

@Component({
  selector: 'app-sdg-button',
  templateUrl: './sdg-button.component.html',
  styleUrls: ['./sdg-button.component.scss']
})
export class SdgButtonComponent {
  @Input() type!: SdgEnum;
  @Input() isSelected!: boolean;
  @Input() noCheck!: boolean;
  @Input() isHighlighted!: boolean;
}
