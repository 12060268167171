import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CognitoVerificationAttributes } from '@core/enums';
import { AuthService } from '@core/services';
import {
  AccountService,
  AccountVerificationService,
  ISendVerificationCode,
  IUpdateAccountResponse
} from '@core/services/account';
import { lastValueFrom, Observable, Subject, takeUntil, tap } from 'rxjs';
import { ChangeNumberComponent } from './change-number/change-number.component';
import {
  IAccountProfile,
  IAccountProfilePreferences,
  IAccountSecurity
} from '@core/models/account-profile.model';

@Component({
  selector: 'app-mobile-update-modal',
  templateUrl: './mobile-update-modal.component.html',
  styleUrls: ['./mobile-update-modal.component.scss']
})
export class MobileUpdateModalComponent implements OnInit {
  @Output() confirmEvent = new EventEmitter<boolean>();
  @ViewChild(ChangeNumberComponent) changeNumberComponent!: ChangeNumberComponent;

  updateMobileNumber$!: Observable<IUpdateAccountResponse>;
  sendAttributesVerification$!: Observable<ISendVerificationCode>;
  showChangeNumberView = false;
  mobileNumberExists = false;
  userDetails!: IAccountProfile;
  accountSecurityDetails!: IAccountSecurity;
  preferencesDetails!: IAccountProfilePreferences;

  private stop$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    private userService: AccountService,
    private accountVerificationService: AccountVerificationService,
    private accountService: AccountService
  ) {
    this.sendAttributesVerification$ = this.accountVerificationService.resendVerificationMessage(
      CognitoVerificationAttributes.Phone,
      this.authService.accessToken
    );
  }

  get cognitoMobileNumber(): string | undefined {
    return this.authService.cognitoUser?.attributes.phone_number;
  }

  ngOnInit(): void {
    this.accountService.getUserDetails
      .pipe(
        takeUntil(this.stop$),
        tap(res => {
          if (res && res.accountSecurity && res.preferences) {
            this.userDetails = res;
            this.accountSecurityDetails = res.accountSecurity;
            this.preferencesDetails = res.preferences;
          }
        })
      )
      .subscribe();
  }

  switchToUpdateMobileView(): void {
    this.showChangeNumberView = true;
  }

  async onUpdateMobileNumber(mobileNumber: string): Promise<void> {
    this.updateMobileNumber$ = this.userService.updateMobileNumber(mobileNumber);

    const updateMobileResult = await lastValueFrom(this.updateMobileNumber$);

    if (!updateMobileResult.error) {
      this.openMobileVerificationModal();

      this.authService.updateUserExistingAuthSessionAttribute({
        phone_number: mobileNumber
      });

      this.setUserMobileNumber(mobileNumber);
    } else {
      this.changeNumberComponent.mobileNumberExists = true;
    }
  }

  async openMobileVerificationModal(): Promise<void> {
    const modalButton = document.getElementById('mobile-verification');

    const sendVerificationMessageResponse = await lastValueFrom(this.sendAttributesVerification$);
    const errorMsgElement = document.getElementById('verification-popup-error');

    errorMsgElement?.classList.add('d-none');

    if (sendVerificationMessageResponse.error) {
      /* If error appears, displays error message in the
        verification pop up component */
      errorMsgElement?.classList.remove('d-none');
    }

    if (modalButton) {
      modalButton.click();
    }
  }

  backToReviewNumber(): void {
    this.showChangeNumberView = false;
  }

  onCancel() {
    this.confirmEvent.emit(false);
  }

  setUserMobileNumber(mobileNumber: string): void {
    this.accountService.setUserDetails = {
      isLicensed: this.userDetails.isLicensed,
      id: this.userDetails.id,
      region: this.userDetails.region,
      preferences: {
        ...this.preferencesDetails,
        mobileNumber: mobileNumber
      },
      accountSecurity: {
        ...this.accountSecurityDetails,
        mobileNumber: mobileNumber
      }
    };
  }
}
