import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { APP_ROUTES } from '@core/constants';
import { ErrorPageGuard } from '@core/services/error-page';
import { MaintenanceGuard } from '@core/services/maintenance/guards';
import { MaintenanceComponent } from '@modules/maintenance/maintenance.component';
import { ErrorPageComponent } from '@shared/components';

const routes: Routes = [
  {
    path: 'maintenance',
    canActivate: [MaintenanceGuard],
    component: MaintenanceComponent
  },
  {
    path: APP_ROUTES.BASE,
    loadChildren: () => import('@modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: APP_ROUTES.BASE,
    loadChildren: () =>
      import('@modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: APP_ROUTES.BASE,
    loadChildren: () => import('@modules/sign-up/sign-up.module').then(m => m.SignUpModule)
  },
  {
    path: APP_ROUTES.PROFILE,
    redirectTo: APP_ROUTES.PREFERENCES,
    pathMatch: 'full'
  },
  {
    path: APP_ROUTES.PROFILE,
    loadChildren: () => import('@modules/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: APP_ROUTES.DASHBOARD,
    loadChildren: () => import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: APP_ROUTES.ERROR,
    canActivate: [ErrorPageGuard],
    component: ErrorPageComponent
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: APP_ROUTES.DASHBOARD }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
