import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES, ERROR_MESSAGES } from '@core/constants';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorPageComponent implements OnInit {
  APP_ROUTES = APP_ROUTES;
  error!: string;
  routerLink!: string;
  networkError!: boolean;
  isSSOError!: boolean;

  get buttonLabel(): string {
    return this.networkError ? 'GO BACK' : 'Back to Sign in';
  }

  constructor(public router: Router) {
    const navigation = this.router.getCurrentNavigation();

    if (navigation && navigation.extras) {
      const state = navigation.extras.state as {
        error: string;
        route?: string;
        networkError?: boolean;
      };
      this.error = state?.error;
      this.routerLink = state?.route || APP_ROUTES.BASE;
      this.networkError = state?.networkError || false;
    }
  }

  ngOnInit(): void {
    this.isSSOError = this.error === ERROR_MESSAGES.NO_SSO_ERROR;
  }
}
