<p-dialog
  [(visible)]="showModal"
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
>
  <div class="dialog-content" appCustomScroll #appScrollElement="appCustomScroll" #scroll>
    <div id="eula">
      <h1 class="h4">{{ "LEGAL.EULA_1" | translate }}</h1>
      <app-eula></app-eula>
    </div>
    <div id="privacy-policy" class="my-5">
      <h1 class="h4">{{ "LEGAL.PRIVACY_POLICY_1" | translate }}</h1>
      <app-privacy-policy></app-privacy-policy>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="dialog-footer">
      <button type="button" class="btn btn-secondary btn-cancel" (click)="onClose()">
        {{ "LEGAL.CLOSE" | translate }}
      </button>
      <button
        *ngIf="showAcceptBtn"
        type="button"
        class="btn btn-primary btn-accept"
        [disabled]="appScrollElement.disableBtn"
        (click)="onAccept()"
      >
        {{ "LEGAL.ACCEPT" | translate }}
      </button>
    </div>
  </ng-template>
</p-dialog>
