import { IAccountProfilePayload } from '@core/models/account-profile.model';

export function getSuccessMessageByActionType(payload: IAccountProfilePayload): string {
  if (payload.preferences) {
    return 'Your Preferences have been successfully updated';
  }
  if (payload.aboutMe) {
    return 'Your About Me changes have been successfully updated';
  }
  if (payload.socialNetwork) {
    return 'Your Social Networks changes have been successfully updated';
  }
  if (payload.accountImages?.avatarData) {
    return 'Your profile image has been successfully updated';
  }
  if (payload.accountImages?.bannerData) {
    return 'Your banner image has been successfully updated';
  }

  return '';
}

export function getErrorMessageByActionType(payload: any): string {
  if (payload.preferences) {
    return 'There was a problem saving your Preferences updates';
  }
  if (payload.aboutMe) {
    return 'There was a problem saving your About Me updates';
  }
  if (payload.socialNetwork) {
    return 'There was a problem saving your Social Networks updates';
  }
  if (payload.accountImages?.avatarData) {
    return 'There was a problem saving your profile image';
  }
  if (payload.accountImages?.bannerData) {
    return 'There was a problem saving your banner image';
  }
  if (payload.password) {
    return 'Your request was unsuccessful, please try again';
  }

  return '';
}
