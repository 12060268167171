import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Icon } from '@core/enums/icon.enums';
import { IAvatar } from '@core/models';
import { IAccountProfile, IAccountProfilePreferences } from '@core/models/account-profile.model';
import { NavigationMenuModel } from '@core/models/navigation.model';
import { AccountService } from '@core/services';
import { environment } from '@env';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-private-left-sidebar',
  templateUrl: './private-left-sidebar.component.html',
  styleUrls: ['./private-left-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivateLeftSidebarComponent implements OnInit {
  @Input() isCollapsed = false;
  @Output() closeEvent = new EventEmitter<boolean>();

  environment = environment;
  Icon = Icon;
  fullName!: string;
  jobTitle!: string;

  avatar!: IAvatar;

  navigationMenu: NavigationMenuModel[] = [
    {
      name: 'dashboard',
      label: 'Dashboard',
      i18n: 'PRIVATE_SHELL.LEFT_SIDEBAR_DASHBOARD',
      route: '/dashboard',
      icon: Icon.DASHBOARD_ICON,
      hidden: false
    },
    {
      name: 'profile',
      route: '/my-profile',
      label: 'My Profile',
      i18n: 'PRIVATE_SHELL.LEFT_SIDEBAR_PROFILE',
      icon: Icon.PROFILE_ICON,
      hidden: false
    },
    {
      name: 'billing',
      label: 'Billing',
      i18n: 'PRIVATE_SHELL.LEFT_SIDEBAR_BILLING',
      route: '/billing',
      icon: Icon.BILLING_ICON,
      hidden: environment.production
    },
    {
      name: 'licenses',
      label: 'My Licenses',
      i18n: 'PRIVATE_SHELL.LEFT_SIDEBAR_LICENSES',
      route: '/licenses',
      icon: Icon.LICENSE_ICON,
      hidden: environment.production
    },
    {
      name: 'products',
      label: 'My Products',
      i18n: 'PRIVATE_SHELL.LEFT_SIDEBAR_PRODUCTS',
      route: '/products',
      icon: Icon.PRODUCTS_ICON,
      hidden: environment.production
    }
  ];

  private stop$ = new Subject<void>();

  constructor(private accountService: AccountService) {
    this.accountService.getUserDetails.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res && res.preferences) {
        this.setAccountDescription(res);
      }
    });
  }

  ngOnInit() {}

  setAccountDescription(accountDetails: IAccountProfile): void {
    if (accountDetails.preferences) {
      const title =
        accountDetails.preferences.title === 'None' ? '' : accountDetails.preferences.title;
      const firstName = accountDetails.preferences.firstName;
      const lastName = accountDetails.preferences.lastName;

      this.fullName = `${title} ${firstName} ${lastName}`;
      this.jobTitle = accountDetails.preferences.jobTitle;

      this.avatar = {
        url: accountDetails.accountImages ? accountDetails.accountImages.avatarUrl : '',
        firstName: accountDetails.preferences.firstName,
        lastName: accountDetails.preferences.lastName,
        round: true
      };
    }
  }

  onClose() {
    this.closeEvent.emit(true);
  }
}
