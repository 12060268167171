import { NgModule } from '@angular/core';
import { AvatarComponent } from './avatar/avatar.component';
import { ConfirmationMessagePopUpComponent } from './confirmation-message-pop-up';
import { ErrorPageComponent } from './error-page';
import { LegalLinksComponent } from './footer/legal-links';
import { LoaderComponent } from './loader';
import { SvgSpriteModule } from './svg-sprite';

import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';
import { PasswordStrengthMeterComponent } from './password-strength-meter/password-strength-meter.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@shared/directives/directives.module';
import { RouterModule } from '@angular/router';
import { LanguageSelectorComponent } from './language-selector';
import { TranslateModule } from '@ngx-translate/core';
import { PrimengModule } from '@shared/primeng.module';
import {
  ChangeNumberComponent,
  MobileUpdateModalComponent,
  MobileVerificationModalComponent
} from './mobile-number';
import { CopyrightComponent } from './footer';
import { EulaComponent, LegalComponent, PrivacyPolicyComponent } from './legal';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SdgButtonComponent } from './sdg-button/sdg-button.component';
import { EditorComponent } from './editor/editor.component';
import { ButtonComponent } from './forms/button/button.component';
import { PasswordStrengthLabelComponent } from './password-strength-label/password-strength-label.component';
import { EyeIconComponent } from './eye-icon/eye-icon.component';
import { ToastComponent } from './toast';
import { NotificationMessagePopUpComponent } from './notification-message-pop-up/notification-message-pop-up.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SvgSpriteModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    PasswordStrengthMeterModule.forRoot(),
    TranslateModule,
    PrimengModule,
    DirectivesModule,
    ImageCropperModule
  ],
  declarations: [
    RecaptchaComponent,
    ErrorPageComponent,
    ConfirmationMessagePopUpComponent,
    PasswordStrengthMeterComponent,
    LegalLinksComponent,
    LegalComponent,
    PrivacyPolicyComponent,
    EulaComponent,
    LoaderComponent,
    AvatarComponent,
    LanguageSelectorComponent,
    MobileUpdateModalComponent,
    MobileVerificationModalComponent,
    ChangeNumberComponent,
    CopyrightComponent,
    ImageUploaderComponent,
    SdgButtonComponent,
    EditorComponent,
    ButtonComponent,
    PasswordStrengthLabelComponent,
    EyeIconComponent,
    ToastComponent,
    NotificationMessagePopUpComponent
  ],
  exports: [
    SvgSpriteModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    PasswordStrengthMeterModule,
    RecaptchaComponent,
    ErrorPageComponent,
    ConfirmationMessagePopUpComponent,
    PasswordStrengthMeterComponent,
    LegalLinksComponent,
    LegalComponent,
    PrivacyPolicyComponent,
    EulaComponent,
    MobileUpdateModalComponent,
    MobileVerificationModalComponent,
    LoaderComponent,
    AvatarComponent,
    LanguageSelectorComponent,
    CopyrightComponent,
    ImageUploaderComponent,
    SdgButtonComponent,
    EditorComponent,
    ButtonComponent,
    PasswordStrengthLabelComponent,
    EyeIconComponent,
    ToastComponent,
    NotificationMessagePopUpComponent
  ]
})
export class ComponentsModule {}
