<header>
  <nav
    class="navbar {{isCollapsed ? 'compacted' : 'expanded'}} navbar-expand navbar-dark fixed-top bg-dark"
  >
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img alt="myVVid-logo" src="/assets/brand/logo-reversed.svg" />
      </a>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav nav-left me-auto mb-2 mb-lg-0">
          <li class="nav-item text-nowrap">
            <a class="nav-link slider" type="button" (click)="onToggle()">
              <app-svg-icon
                class="svg-icon"
                styleClass="d-inline-block"
                [icon]="Icon.HAMBURGER_MENU"
              ></app-svg-icon>
              <span class="slider-text">{{ 'PRIVATE_SHELL.TOP_NAV_TOGGLER' | translate }}</span>
            </a>
          </li>
        </ul>
        <ul class="navbar-nav me-4 mb-2 mb-lg-0" *ngIf="!environment.production">
          <li class="nav-item">
            <div class="input-group search">
              <input
                type="text"
                class="form-control d-sm-none d-md-block"
                placeholder="{{ 'PRIVATE_SHELL.TOP_NAV_SEARCH' | translate }}"
                aria-label="Search"
                aria-describedby="search"
              />
              <div class="input-group-append">
                <app-svg-icon
                  class="svg-icon"
                  styleClass="d-block"
                  [icon]="Icon.SEARCH"
                ></app-svg-icon>
              </div>
              <div class="input-line w-100 d-sm-none d-md-block"></div>
            </div>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link help"
              pTooltip="{{ 'PRIVATE_SHELL.TOP_NAV_HELP' | translate }}"
              tooltipPosition="bottom"
            >
              <app-svg-icon
                class="svg-icon"
                styleClass="d-block"
                [icon]="Icon.QUESTION"
              ></app-svg-icon>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link bell"
              pTooltip="{{ 'PRIVATE_SHELL.TOP_NAV_NOTIFICATIONS' | translate }}"
              tooltipPosition="bottom"
            >
              <app-svg-icon class="svg-icon" styleClass="d-block" [icon]="Icon.BELL"></app-svg-icon>
              <div class="bell-counter"><span class="bell-counter-text">3</span></div>
            </a>
          </li>
        </ul>
        <ul class="navbar-nav nav-apps mb-2 mb-lg-0">
          <li class="nav-item ms-2">
            <div class="dropdown">
              <a
                class="nav-link"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                pTooltip="{{ 'PRIVATE_SHELL.TOP_NAV_APPS' | translate }}"
                tooltipPosition="bottom"
              >
                <app-svg-icon
                  class="svg-icon"
                  styleClass="d-block"
                  [icon]="Icon.CANDY_MENU"
                ></app-svg-icon>
              </a>
              <ul class="dropdown-menu dropdown-menu-end bg-light">
                <ng-container *ngFor="let app of apps">
                  <!-- <li
                    class="dropdown-item py-4"
                    [ngClass]="{ disabled: disableAppForNonLicencedAccount(app ) }"
                  >
                    <img
                      (click)="redirect(app)"
                      alt="{{app.client_name}}"
                      src="/assets/apps/{{app.client_logo}}"
                      class="img-fluid app-logo"
                    />
                  </li> -->
                  <li
                    class="dropdown-item py-4"
                  >
                    <img
                      (click)="redirect(app)"
                      alt="{{app.client_name}}"
                      src="/assets/apps/{{app.client_logo}}"
                      class="img-fluid app-logo"
                    />
                  </li>
                </ng-container>
                <li class="dropdown-item text-center">
                  <button type="button" class="btn btn-link btn-signout" (click)="signOut()">
                    Sign Out
                  </button>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
