<div class="eula-container">
  <p class="mt-4">
    HIVVE GROUP LTD ('hivve') IS WILLING TO PROVIDE ACCESS TO CERTAIN PROPRIETARY PRODUCTS TO YOU ON
    THE TERMS OF THIS END USER LICENSE AGREEMENT (THE 'EULA'). YOU MUST READ THIS EULA CAREFULLY
    BEFORE USING HIVVE PRODUCTS. THIS EULA CONSTITUTES A LEGAL AND ENFORCEABLE CONTRACT BETWEEN YOU
    AND HIVVE. BY CLICKING 'I AGREE', USING HIVVE PRODUCTS OR OTHERWISE INDICATING AGREEMENT
    ELECTRONICALLY, YOU AGREE TO THIS EULA. IF YOU DO NOT AGREE TO THIS EULA, YOU SHOULD NOT CLICK
    'I AGREE' AND/OR SHOULD IMMEDIATELY MAKE NO FURTHER USE OF HIVVE PRODUCTS.
  </p>
  <ol type="I">
    <li>
      <h4 class="mt-4">DEFINITIONS</h4>
      <p>
        myVVid: hivve's proprietary software application currently marketed as 'myVVid', as well as
        any updates to it and any new versions which are made generally available by hivve from time
        to time. myVVid is a cloud hosted SaaS (Software as a Service) access control system with a
        customised user interface which enables users to gain access to any/ all hivve products.
        hivve Products: shall mean any SaaS product, which is made available through myVVid,
        including ImpactTracker, TrackImpact, ImpactAcademy (e-learning), or any other content or
        material. An End-User License Agreement (EULA) applies to each hivve Product.
      </p>
      <p>
        User: individual(s) who are authorised by hivve to use the hivve Products, for whom
        subscriptions to a Service have been purchased, and who have been supplied user
        identifications and passwords by hivve at their request. Users may include but are not
        limited to researchers, consultants, collaborators and agents; or third parties with which
        hivve transact business.
      </p>
    </li>
    <li>
      <h4 class="mt-4 mb-2">HIVVE PRODUCTS EULA</h4>
      <ol class="number-list">
        <li>
          <h5 class="mt-2 mb-4">ImpactTracker</h5>
          <h6>INTERPRETATION</h6>
          <ol class="number-list">
            <li>
              <p>The following definitions apply to this EULA:</p>
              <p>'hivve': HIVVE GROUP LTD</p>
              <p>
                'Intellectual Property Rights': all copyright and related rights, patents, rights to
                inventions, utility models, trade marks, service marks, trade, business and domain
                names, rights in trade dress or get-up, rights in goodwill or to sue for passing
                off, unfair competition rights, rights in designs, rights in computer software,
                database right, topography rights, moral rights, rights in confidential information
                (including know-how and trade secrets) and any other intellectual property rights,
                in each case whether registered or unregistered and including all applications for
                and renewals or extensions of such rights, and all similar or equivalent rights or
                forms of protection.
              </p>
              <p>'License': the license granted under clause 1.9.</p>
              <p>
                'LSA': the License and Services Agreement to which this End User License Agreement
                relates.
              </p>
              <p>
                'ImpactTracker': hivve's proprietary software application currently marketed as the
                'ImpactTracker', as well as any updates to it and any new versions which are made
                generally available by hivve from time to time. The ImpactTracker is a cloud hosted
                research management system with a customised user interface which enables Third
                Party Content to be collected, organised and analysed for the purposes of evaluating
                the impact of a project on an organisation.
              </p>
              <p>
                'Third Party Content': shall mean any content of a third party, which is collected,
                organised or analysed by your use of the ImpactTracker, including all text, images,
                graphics, pictures, numbers, or any other content or material that you submit,
                upload or make accessible to the ImpactTracker.
              </p>
            </li>
            <li>
              <p>A 'person' includes a natural person, corporate or unincorporated body.</p>
            </li>
            <li>
              <p>
                Unless the context otherwise requires, words in the singular include the plural and
                in the plural include the singular and references to one gender are references to
                the other genders.
              </p>
            </li>
            <li>
              <p>
                The words 'include' and 'including' (or similar) shall be deemed to have the words
                'without limitation' after them.
              </p>
            </li>
            <li><p>A reference to 'writing' or 'written' excludes email.</p></li>
          </ol>
          <h6 class="mt-4 mb-2">YOUR USE OF THE IMPACTTRACKER</h6>
          <ol style="counter-increment: item 5" class="number-list">
            <li>
              <p>
                Your access to the ImpactTracker shall be limited to you. You will be allocated a
                username and password by hivve.
              </p>
            </li>
            <li>
              <p>
                You shall keep your password confidential. hivve shall not be liable for any losses
                or damage suffered by you due to the disclosure of any passwords.
              </p>
            </li>
            <li>
              <p>
                Your use of the ImpactTracker shall not violate any applicable law, regulation or
                any terms of use applicable to the use of Third Party Content.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">LICENSE OF THE IMPACTTRACKER</h6>
          <ol style="counter-increment: item 8" class="number-list">
            <li>
              <p>
                hivve hereby grants you a license to use the ImpactTracker in accordance with the
                terms of this EULA (the 'License').
              </p>
            </li>
            <li>
              <p>
                The License is revocable, non-exclusive, non-transferable and non-sublicensable.
              </p>
            </li>
            <li>
              <p>
                The License is granted to you only. You shall not share the ImpactTracker with any
                third party.
              </p>
            </li>
            <li>
              <p>You shall not use the ImpactTracker to:</p>
              <ol class="number-list">
                <li><p>provide services to third parties; or</p></li>
                <li><p>build a product or service which competes with the ImpactTracker.</p></li>
              </ol>
            </li>
            <li>
              <p>
                You shall use all reasonable endeavors to prevent any unauthorised access to, or use
                of, the ImpactTracker and shall notify hivve promptly of any such unauthorised
                access or use.
              </p>
            </li>
            <li>
              <p>
                hivve may modify, suspend or discontinue any part of the ImpactTracker or otherwise
                make any changes it considers desirable to the ImpactTracker.
              </p>
            </li>
            <li>
              <p>
                You shall not use any taxonomy information or processes from the ImpactTracker
                outside of your use of the ImpactTracker.
              </p>
            </li>
            <li>
              <p>
                You are encouraged to keep your back-up copy of any data, content or information
                made available to the ImpactTracker.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">PROPRIETARY RIGHTS</h6>
          <ol style="counter-increment: item 16" class="number-list">
            <li>
              <p>
                hivve and/or its licensors own all Intellectual Property Rights in the
                ImpactTracker. Other than as expressly set out in this EULA, hivve does not grant
                you any Intellectual Property Rights in respect of the ImpactTracker or any other
                materials unless expressly provided in this EULA.
              </p>
            </li>
            <li>
              <p>
                You may not copy, decompile, reverse engineer, modify or otherwise deal in any way
                with the ImpactTracker. You may not remove any proprietary notices attached to the
                ImpactTracker.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">THIRD PARTY CONTENT</h6>
          <ol style="counter-increment: item 18" class="number-list">
            <li>
              <p>
                hivve is a mere conduit for all Third Party Content which is accessed or processed
                by your use of the ImpactTracker and is in no way involved with the information
                contained in any Third Party Content. hivve does not:
              </p>
              <ol class="number-list">
                <li><p>initiate the transmission of Third Party Content;</p></li>
                <li><p>select the receiver of any transmitted Third Party Content; or</p></li>
                <li>
                  <p>
                    select or modify the information contained in any Third Party Content (other
                    than technical manipulations which take place in the course of the transmission
                    of the Third Party Content and which do not alter the integrity of the
                    information contained in the Third Party Content).
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Any Intellectual Property Rights in the Third Party Content shall remain with its
                owners or licensors.
              </p>
            </li>
            <li>
              <p>
                hivve may conduct analysis for its own benefit on any Third Party Content not
                designated as 'Confidential' on the ImpactTracker. Where any Third Party Content may
                contain personal data, hivve shall only undertake such further processing in
                accordance with applicable data protection laws. hivve shall own all Intellectual
                Property Rights in the product of such analysis.
              </p>
            </li>
            <li>
              <p>
                You will indemnify, defend and hold harmless hivve, from and against any claims,
                costs, damages, losses, liabilities and expenses (including legal fees) relating to
                any claims, actions, suits or proceedings by third parties against hivve arising out
                of or related in any way to either Party's use of Third Party Content in accordance
                with this EULA.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">CONFIDENTIALITY</h6>
          <ol style="counter-increment: item 22" class="number-list">
            <li>
              <p>
                In this clause, 'Confidential Information' means any information that is clearly
                labeled or identified as confidential or ought reasonably be treated as being
                confidential. Confidential Information includes the ImpactTracker and any Third
                Party Content (other than anonymised or aggregated derivatives of Third Party
                Content created pursuant to clause 1.21). Confidential Information excludes any
                information which:
              </p>
              <ol class="number-list">
                <li>
                  <p>
                    is or becomes publicly known other than through a breach of this EULA or other
                    obligation of confidentiality;
                  </p>
                </li>
                <li>
                  <p>was in the receiving Party's lawful possession before the disclosure;</p>
                </li>
                <li>
                  <p>
                    is lawfully disclosed to the receiving Party by a third party without
                    restriction on disclosure;
                  </p>
                </li>
                <li>
                  <p>
                    is independently developed by the receiving Party and that independent
                    development can be shown by written evidence; or
                  </p>
                </li>
                <li>
                  <p>
                    is required to be disclosed by law, by any court of competent jurisdiction or by
                    any regulatory or administrative body.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Each Party shall hold the other's Confidential Information in confidence and not
                make the other's Confidential Information available to any third party unless that
                third party is subject to an equivalent duty of confidentiality. Neither Party shall
                use the other's Confidential Information for any purpose other than the
                implementation of this EULA.
              </p>
            </li>
            <li>
              <p>
                Each party shall take all reasonable steps to ensure that the other's Confidential
                Information to which it has access is not disclosed or distributed by its employees,
                agents or independent contractors in breach of the terms of this EULA.
              </p>
            </li>
            <li>
              <p>This clause shall survive termination of this EULA for a period of 5 years.</p>
            </li>
          </ol>
        </li>
        <li>
          <h5 class="mt-2 mb-4">TrackImpact</h5>
          <h6>INTERPRETATION</h6>
          <ol class="number-list">
            <li>
              <p>The following definitions apply to this EULA:</p>
              <p>'hivve': HIVVE GROUP LTD</p>
              <p>
                'Intellectual Property Rights': all copyright and related rights, patents, rights to
                inventions, utility models, trade marks, service marks, trade, business and domain
                names, rights in trade dress or get-up, rights in goodwill or to sue for passing
                off, unfair competition rights, rights in designs, rights in computer software,
                database right, topography rights, moral rights, rights in confidential information
                (including know-how and trade secrets) and any other intellectual property rights,
                in each case whether registered or unregistered and including all applications for
                and renewals or extensions of such rights, and all similar or equivalent rights or
                forms of protection.
              </p>
              <p>'License': the license granted under clause 2.9.</p>
              <p>
                'TrackImpact': hivve's proprietary software application currently marketed as
                'TrackImpact', as well as any updates to it and any new versions which are made
                generally available by hivve from time to time. TrackImpact is a cloud hosted
                research management system with a customised user interface which enables Third
                Party Content to be collected, organised and analysed for the purposes of evaluating
                the impact of a project on an organisation.
              </p>
              <p>
                'Third Party Content': shall mean any content of a third party, which is made
                available through TrackImpact, including all text, images, graphics, pictures,
                numbers, or any other content or material.
              </p>
            </li>
            <li><p>A 'person' includes a natural person, corporate or unincorporated body.</p></li>
            <li>
              <p>
                Unless the context otherwise requires, words in the singular include the plural and
                in the plural include the singular and references to one gender are references to
                the other genders.
              </p>
            </li>
            <li>
              <p>
                The words 'include' and 'including' (or similar) shall be deemed to have the words
                'without limitation' after them.
              </p>
            </li>
            <li><p>A reference to 'writing' or 'written' excludes email.</p></li>
          </ol>
          <h6 class="mt-4 mb-2">YOUR USE OF TRACKIMPACT</h6>
          <ol style="counter-increment: item 5" class="number-list">
            <li>
              <p>
                Your access to TrackImpact shall be limited to you. You will be allocated a username
                and password by hivve.
              </p>
            </li>
            <li>
              <p>
                You shall keep your password confidential. hivve shall not be liable for any losses
                or damage suffered by you due to the disclosure of any passwords.
              </p>
            </li>
            <li>
              <p>
                Your use of TrackImpact shall not violate any applicable law, regulation or any
                terms of use applicable to the use of Third Party Content.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">LICENSE OF TRACKIMPACT</h6>
          <ol style="counter-increment: item 8" class="number-list">
            <li>
              <p>
                hivve hereby grants you a license to use TrackImpact in accordance with the terms of
                this EULA.
              </p>
            </li>
            <li>
              <p>
                The License is revocable, non-exclusive, non-transferable and non-sublicensable.
              </p>
            </li>
            <li>
              <p>
                The License is granted to you only. You shall not share TrackImpact with any third
                party.
              </p>
            </li>
            <li>
              <p>You shall not use TrackImpact to:</p>
              <ol class="number-list">
                <li><p>provide services to third parties; or</p></li>
                <li><p>build a product or service which competes with TrackImpact</p></li>
              </ol>
            </li>
            <li>
              <p>
                You shall use all reasonable endeavors to prevent any unauthorised access to, or use
                of, TrackImpact and shall notify hivve promptly of any such unauthorised access or
                use.
              </p>
            </li>
            <li>
              <p>
                hivve may modify, suspend or discontinue any part of TrackImpact or otherwise make
                any changes it considers desirable to TrackImpact.
              </p>
            </li>
            <li>
              <p>
                You shall not use any taxonomy information or processes from TrackImpact outside of
                your use of TrackImpact.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">PROPRIETARY RIGHTS</h6>
          <ol style="counter-increment: item 15" class="number-list">
            <li>
              <p>
                hivve and/or its licensors own all Intellectual Property Rights in TrackImpact.
                Other than as expressly set out in this EULA, hivve does not grant you any
                Intellectual Property Rights in respect of TrackImpact or any other materials unless
                expressly provided in this EULA.
              </p>
            </li>
            <li>
              <p>
                You may not copy, decompile, reverse engineer, modify or otherwise deal in any way
                with TrackImpact. You may not remove any proprietary notices attached to
                TrackImpact.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">THIRD PARTY CONTENT</h6>
          <ol style="counter-increment: item 17" class="number-list">
            <li>
              <p>
                hivve is a mere conduit for all Third Party Content which is accessed or processed
                by your use of TrackImpact and is in no way involved with the information contained
                in any Third Party Content. hivve does not:
              </p>
              <ol class="number-list">
                <li><p>initiate the transmission of Third Party Content;</p></li>
                <li><p>select the receiver of any transmitted Third Party Content; or</p></li>
                <li>
                  <p>
                    select or modify the information contained in any Third Party Content (other
                    than technical manipulations which take place in the course of the transmission
                    of the Third Party Content and which do not alter the integrity of the
                    information contained in the Third Party Content).
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Any Intellectual Property Rights in the Third Party Content shall remain with its
                owners or licensors.
              </p>
            </li>
            <li>
              <p>
                hivve may conduct analysis for its own benefit on any Third Party Content not
                designated as 'Confidential' on TrackImpact. hivve shall own all Intellectual
                Property Rights in the product of such analysis.
              </p>
            </li>
            <li>
              <p>
                You will indemnify, defend and hold harmless hivve, from and against any claims,
                costs, damages, losses, liabilities and expenses (including legal fees) relating to
                any claims, actions, suits or proceedings by third parties against hivve arising out
                of or related in any way to either party's use of Third Party Content in accordance
                with this EULA.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">CONFIDENTIALITY</h6>
          <ol style="counter-increment: item 21" class="number-list">
            <li>
              <p>
                In this clause, 'Confidential Information' means any information that is clearly
                labeled or identified as confidential or ought reasonably be treated as being
                confidential. Confidential Information includes TrackImpact and any Third Party
                Content (other than anonymised or aggregated derivatives of Third Party Content
                created pursuant to clause 2.20). Confidential Information excludes any information
                which:
              </p>
              <ol class="number-list">
                <li>
                  <p>
                    is or becomes publicly known other than through a breach of this EULA or other
                    obligation of confidentiality;
                  </p>
                </li>
                <li>
                  <p>was in the receiving party's lawful possession before the disclosure;</p>
                </li>
                <li>
                  <p>
                    is lawfully disclosed to the receiving party by a third party without
                    restriction on disclosure;
                  </p>
                </li>
                <li>
                  <p>
                    is independently developed by the receiving party and that independent
                    development can be shown by written evidence; or
                  </p>
                </li>
                <li>
                  <p>
                    is required to be disclosed by law, by any court of competent jurisdiction or by
                    any regulatory or administrative body.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Each party shall hold the other's Confidential Information in confidence and not
                make the other's Confidential Information available to any third party unless that
                third party is subject to an equivalent duty of confidentiality. Neither party shall
                use the other's Confidential Information for any purpose other than the
                implementation of this EULA.
              </p>
            </li>
            <li>
              <p>
                Each party shall take all reasonable steps to ensure that the other's Confidential
                Information to which it has access is not disclosed or distributed by its employees,
                agents or independent contractors in breach of the terms of this EULA.
              </p>
            </li>
            <li>
              <p>This clause shall survive termination of this EULA for a period of 5 years.</p>
            </li>
          </ol>
        </li>
        <li>
          <h5 class="mt-2 mb-4">ImpactAcademy (e-learning)</h5>
          <h6>INTERPRETATION</h6>
          <ol class="number-list">
            <li>
              <p>The following definitions apply to this EULA:</p>
              <p>
                'e-learning courses': hivve's online learning portal (and any updates to it and new
                versions which are made available by hivve from time to time), delivering
                'e-learning courses'. Users can access the online learning courses and track their
                progress via a dashboard.
              </p>
              <p>'hivve': HIVVE GROUP LTD</p>
              <p>
                'Intellectual Property Rights': all copyright and related rights, patents, rights to
                inventions, utility models, trade marks, service marks, trade, business and domain
                names, rights in trade dress or get-up, rights in goodwill or to sue for passing
                off, unfair competition rights, rights in designs, rights in computer software,
                database right, topography rights, moral rights, rights in confidential information
                (including know-how and trade secrets) and any other intellectual property rights,
                in each case whether registered or unregistered and including all applications for
                and renewals or extensions of such rights, and all similar or equivalent rights or
                forms of protection.
              </p>
              <p>'License': the license granted under clause 3.8.</p>
              <p>
                'LSA': the License and Services Agreement to which this End User license Agreement
                relates.
              </p>
              <p>
                'Third Party Content': means any data, information, content including trademarks,
                logos, software or other Intellectual Property Rights of a third party, including
                any which is accessed by your use of the e-learning courses.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">YOUR USE OF THE IMPACTACADEMY E-LEARNING COURSES</h6>
          <ol style="counter-increment: item 1" class="number-list">
            <li>
              <p>
                Your access to the e-learning courses shall be limited to you. When you register,
                you will choose a username and password and insert the voucher allocated to you from
                your University (either upon registration or by redeeming it within your account).
                This voucher will automatically enroll you into the relevant module(s) when you
                redeem it ('Enrolment Date').
              </p>
            </li>
            <li>
              <p>
                You will have 1 year to complete the module from the Enrolment Date ('Enrolment
                Term'). Before the end of such 1 year period, you will receive an electronic
                notification.
              </p>
            </li>
            <li>
              <p>
                You shall keep your password confidential. hivve shall not be liable for any losses
                or damage suffered by you due to the disclosure of any passwords.
              </p>
            </li>
            <li>
              <p>
                Your use of the e-learning courses shall not violate any applicable law, regulation
                or any terms of use applicable to the use of Third Party Content.
              </p>
            </li>
            <li>
              <p>
                The e-learning courses are provided for general information and training purposes
                only and you must obtain professional or specialist advice before taking, or
                refraining from taking, any action on the basis of information obtained through the
                e-learning courses.
              </p>
            </li>
            <li>
              <p>
                You are solely responsible for ensuring that you have sufficient and compatible
                hardware, software and internet connectivity necessary for use of the e-learning
                courses.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">LICENSE OF THE IMPACTACADEMY E-LEARNING COURSES</h6>
          <ol style="counter-increment: item 7" class="number-list">
            <li>
              <p>
                hivve hereby grants you a license to use the e-learning courses in accordance with
                the terms of this EULA (the 'License').
              </p>
            </li>
            <li>
              <p>
                The License is revocable, non-exclusive, non-transferable and non-sublicensable.
              </p>
            </li>
            <li>
              <p>
                The License is granted to you only. You shall not share the e-learning courses with
                any third party.
              </p>
            </li>
            <li>
              <p>You shall not use the e-learning courses to:</p>
              <ol class="number-list">
                <li><p>provide services to third parties; or</p></li>
                <li>
                  <p>build a product or service which competes with the e-learning courses.</p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                You shall use all reasonable endeavors to prevent any unauthorised access to, or use
                of, the e-learning courses and shall notify hivve promptly of any such unauthorised
                access or use.
              </p>
            </li>
            <li>
              <p>
                hivve may modify, suspend or discontinue any part of the e-learning courses or
                otherwise make any changes it considers desirable to the e-learning courses.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">PROPRIETARY RIGHTS</h6>
          <ol style="counter-increment: item 13" class="number-list">
            <li>
              <p>
                hivve and/or its licensors own all Intellectual Property Rights in the e-learning
                courses materials and content. Other than as expressly set out in this EULA, hivve
                does not grant you any Intellectual Property Rights in respect of the e-learning
                courses or any other materials unless expressly provided in this EULA.
              </p>
            </li>
            <li>
              <p>
                You may not copy, decompile, reverse engineer, modify or otherwise deal in any way
                with the e-learning courses. You may not remove any proprietary notices attached to
                the e-learning courses.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">THIRD PARTY CONTENT</h6>
          <ol style="counter-increment: item 15" class="number-list">
            <li>
              <p>
                hivve may host Third Party Content which is accessed by your use of the e-learning
                courses and is in no way involved with the information contained in any Third Party
                Content. hivve does not:
              </p>
              <ol class="number-list">
                <li><p>initiate the transmission of Third Party Content;</p></li>
                <li><p>select the receiver of any transmitted Third Party Content; or</p></li>
                <li>
                  <p>
                    select or modify the information contained in any Third Party Content (other
                    than technical manipulations which take place in the course of the transmission
                    of the Third Party Content and which do not alter the integrity of the
                    information contained in the Third Party Content).
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Any Intellectual Property Rights in the Third Party Content shall remain with its
                owners or licensors.
              </p>
            </li>
            <li>
              <p>
                hivve may conduct analysis for its own benefit on any Third Party Content not
                designated as 'Confidential' on ImpactAcademy (e-learning). hivve shall own all
                Intellectual Property Rights in the product of such analysis.
              </p>
            </li>
            <li>
              <p>
                You will indemnify, defend and hold harmless hivve, from and against any claims,
                costs, damages, losses, liabilities and expenses (including legal fees) relating to
                any claims, actions, suits or proceedings by third parties against hivve arising out
                of or related in any way to either Party's use of Third Party Content in accordance
                with this EULA.
              </p>
            </li>
          </ol>
          <h6 class="mt-4 mb-2">CONFIDENTIALITY</h6>
          <ol style="counter-increment: item 19" class="number-list">
            <li>
              <p>
                In this clause, 'Confidential Information' means any information that is clearly
                labeled or identified as confidential or ought reasonably be treated as being
                confidential. Confidential Information includes the e-learning courses and any Third
                Party Content (other than anonymised or aggregated derivatives of Third Party
                Content created pursuant to clause 3.18). Confidential Information excludes any
                information which:
              </p>
              <ol class="number-list">
                <li>
                  <p>
                    is or becomes publicly known other than through a breach of this EULA or other
                    obligation of confidentiality;
                  </p>
                </li>
                <li>
                  <p>was in the receiving Party's lawful possession before the disclosure;</p>
                </li>
                <li>
                  <p>
                    is lawfully disclosed to the receiving Party by a third party without
                    restriction on disclosure;
                  </p>
                </li>
                <li>
                  <p>
                    is independently developed by the receiving Party and that independent
                    development can be shown by written evidence; or
                  </p>
                </li>
                <li>
                  <p>
                    is required to be disclosed by law, by any court of competent jurisdiction or by
                    any regulatory or administrative body.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Each Party shall hold the other's Confidential Information in confidence and not
                make the other's Confidential Information available to any third party unless that
                third party is subject to an equivalent duty of confidentiality. Neither Party shall
                use the other's Confidential Information for any purpose other than the
                implementation of this EULA.
              </p>
            </li>
            <li>
              <p>
                Each party shall take all reasonable steps to ensure that the other's Confidential
                Information to which it has access is not disclosed or distributed by its employees,
                agents or independent contractors in breach of the terms of this EULA.
              </p>
            </li>
            <li>
              <p>This clause shall survive termination of this EULA for a period of 5 years.</p>
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      <h4 class="mt-4">LIMITATION OF LIABILITY</h4>
      <ol type="A">
        <li>
          <p>
            This clause III sets out hivve's entire financial liability (including any liability for
            the acts or omissions of its employees, agents and subcontractors) to you:
          </p>
          <ol type="1">
            <li><p>arising under or in connection with this EULA;</p></li>
            <li><p>in respect of any use made by you of the e-learning courses;</p></li>
            <li><p>any Third Party Content; and</p></li>
            <li>
              <p>
                in respect of any representation, misrepresentation (whether innocent or negligent),
                statement or tortious act or omission (including negligence) arising under or in
                connection with this EULA.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            Except as expressly and specifically provided in this EULA you assume sole
            responsibility for your use of the e-learning courses and for the results of, or
            conclusions drawn from, such use.
          </p>
        </li>
        <li>
          <p>
            The e-learning courses are provided 'as is' to the fullest extent permissible pursuant
            to applicable law. hivve disclaims all warranties and conditions express or implied,
            including, but not limited to, implied warranties of satisfactory quality and fitness
            for a particular purpose, in relation to the e-learning courses, its use and the results
            of such use. hivve specifically disclaims any warranty:
          </p>
          <ol type="1">
            <li>
              <p>
                that the e-learning courses and its availability shall be uninterrupted, delayed or
                error-free
              </p>
            </li>
            <li>
              <p>
                for any loss of user information stored on the e-learning course dashboard
                (including grades, certificates or achievements). As such, we advise that you back
                up or keep additional copies of this information;
              </p>
            </li>
            <li><p>that defects shall be corrected;</p></li>
            <li><p>that there are no viruses or other harmful components;</p></li>
            <li><p>that the security methods employed shall be sufficient; or</p></li>
            <li><p>regarding correctness, accuracy, or reliability.</p></li>
          </ol>
        </li>
        <li>
          <p>
            All warranties, representations, conditions and all other terms of any kind whatsoever
            implied by statute or common law are excluded from this EULA to the fullest extent
            permitted by law.
          </p>
        </li>
        <li>
          <p>Nothing in this EULA excludes the liability of hivve:</p>
          <ol type="1">
            <li><p>for death or personal injury caused by hivve's negligence;</p></li>
            <li><p>for fraud or fraudulent misrepresentation; or</p></li>
            <li><p>any statutory liability not capable of limitation.</p></li>
          </ol>
        </li>
        <li>
          <p>
            Subject to clause III.E, hivve shall not be liable whether in tort (including for
            negligence or breach of statutory duty), contract, misrepresentation (whether innocent
            or negligent), restitution or otherwise for any loss of profits, loss of business,
            depletion of goodwill and/or similar losses or loss or corruption of data or
            information, or pure economic loss, or for any special, indirect or consequential loss
            costs, damages, charges or expenses however arising under this EULA.
          </p>
        </li>
        <li>
          <p>
            Subject to clause III.E, hivve's total aggregate liability in contract, tort (including
            negligence or breach of statutory duty), misrepresentation (whether innocent or
            negligent), restitution or otherwise, arising in connection with the performance or
            contemplated performance of this EULA shall be limited to £10.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <h4 class="mt-4">DATA PROTECTION</h4>
      <ol type="A">
        <li>
          <p>
            In this EULA, the terms 'controller', 'processor', 'data subject', 'personal data',
            'process', 'processing', 'consent' and 'special categories of personal data' (previously
            referred to as 'sensitive personal data') have the meanings ascribed to them in the
            General Data Protection Regulation 2016/679 (the 'GDPR').
          </p>
        </li>
        <li>
          <p>
            Save for in relation to Clause 1.21 (as applicable), the Parties intend that hivve is a
            processor and only processes personal data on behalf of the controller. The controller
            is defined in the LSA. If personal data is provided by you to hivve, hivve shall only
            process such personal data on documented instructions from the controller.
          </p>
        </li>
        <li>
          <p>
            hivve shall only collect and process personal data in accordance with the legal basis of
            entering into and performing a contract with you for the purposes of delivering the
            e-learning courses. For further detail on your rights and how hivve processes personal
            data, please refer to hivve's privacy policy found here.
          </p>
        </li>
        <li>
          <p>
            hivve shall take appropriate and commercially reasonable technical and organisational
            measures to protect against unauthorised or unlawful processing of any personal data
            provided by you, or any accidental loss, destruction or damage of such data.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <h4 class="mt-4">TERMINATION</h4>
      <ol type="A">
        <li>
          <p>
            This EULA will terminate after 2 years, or otherwise in accordance with the termination
            of the LSA.
          </p>
        </li>
        <li>
          <p>
            hivve will continue to hold your data following completion of the respective module for
            a further year beyond the Enrolment Term. After this point, the information stored on
            your e-learning account (including course information, goals and progress) will be
            permanently deleted.
          </p>
        </li>
        <li>
          <p>
            hivve may terminate this EULA at any time (prior to the 2 years) if we reasonably
            suspect that you have breached any term of this EULA. You may terminate this EULA on
            reasonable written notice to hivve.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <h4 class="mt-4">WAIVER</h4>
      <p>A waiver of any right under this EULA is only effective if it is in writing.</p>
    </li>
    <li>
      <h4 class="mt-4">SEVERANCE</h4>
      <p>
        If any provision (or part of a provision) of this EULA is found to be invalid, unenforceable
        or illegal, the other provisions (or parts of any provisions) shall remain in force.
      </p>
    </li>
    <li>
      <h4 class="mt-4">ENTIRE AGREEMENT</h4>
      <p>
        This EULA constitutes the whole agreement between the parties and supersedes any previous
        agreement between them.
      </p>
    </li>
    <li>
      <h4 class="mt-4">ASSIGNMENT</h4>
      <p>
        You shall not assign, transfer, charge, sub-contract or deal in any other manner with all or
        any of its rights or obligations under this EULA, without the prior written consent of
        hivve.
      </p>
    </li>
    <li>
      <h4 class="mt-4">THIRD PARTY RIGHTS</h4>
      <p>
        This EULA does not confer any rights on any person or party (other than the parties to this
        EULA and (where applicable) their successors and permitted assigns) pursuant to the
        Contracts (Rights of Third Parties) Act 1999.
      </p>
    </li>
    <li>
      <h4 class="mt-4">GOVERNING LAW AND JURISDICTION</h4>
      <p>
        This EULA shall be governed by, and construed in accordance with, the laws of England and
        Wales and the parties irrevocably submit to the exclusive jurisdiction of the English
        Courts.
      </p>
    </li>
  </ol>
</div>
