import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@core/enums';

@Component({
  selector: 'app-eye-icon',
  templateUrl: './eye-icon.component.html',
  styleUrls: ['./eye-icon.component.scss']
})
export class EyeIconComponent implements OnInit {
  @Input() checkedProperty!: boolean;
  Icon = Icon;

  constructor() {}

  ngOnInit(): void {}
}
