<div class="eye-icon-wrapper">
  <app-svg-icon
    *ngIf="checkedProperty"
    class="svg-icon svg-icon-eye"
    [icon]="Icon.VISIBLE_ICON"
  ></app-svg-icon>
  <app-svg-icon
    *ngIf="!checkedProperty"
    class="svg-icon svg-icon-eye-invisible"
    [icon]="Icon.INVISIBLE_ICON"
  ></app-svg-icon>
</div>
