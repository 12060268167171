<ng-container
  appFullContent
  bodyClass="d-flex flex-column h-100 bg-gradient"
  rootClass="d-flex flex-column h-100"
>
  <app-private-top-navbar
    [isCollapsed]="isCollapsed"
    (toggleEvent)="onToggle($event)"
  ></app-private-top-navbar>
  <div class="container-fluid {{ isCollapsed ? 'compacted' : 'expanded' }}">
    <app-private-left-sidebar
      (closeEvent)="onClose($event)"
      [isCollapsed]="isCollapsed"
      class="sidebar bg-dark"
    ></app-private-left-sidebar>
    <main>
      <div class="container-lg">
        <app-toast></app-toast>
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
  <app-private-footer class="mt-auto"></app-private-footer>
</ng-container>
