import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { Hub, HubClass } from '@aws-amplify/core/lib-esm/Hub';

import {
  AuthorizeGuard,
  AnonymousGuard,
  AuthService,
  ErrorPageGuard,
  MaintenanceGuard,
  AuthInterceptor,
  AccountService,
  AuthHelpers,
  LegalService,
  ToastService,
  MaintenanceService
} from './services';
import { LoaderInterceptor, LoaderService } from './services/loader';
import { UnauthorizedInterceptor } from './services/auth/interceptors/unauthorized';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AuthService,
    AuthHelpers,
    AccountService,
    LoaderService,
    LegalService,
    ToastService,
    MaintenanceService,
    AuthorizeGuard,
    AnonymousGuard,
    ErrorPageGuard,
    MaintenanceGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: AuthClass, useValue: Auth },
    { provide: HubClass, useValue: Hub }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core modules in the AppModule only.`
      );
    }
  }
}
