import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-private-shell',
  templateUrl: './private-shell.component.html',
  styleUrls: ['./private-shell.component.scss']
})
export class PrivateShellComponent implements OnInit {
  isCollapsed = false;

  constructor() {}

  ngOnInit(): void {}

  onClose(value: boolean) {
    this.isCollapsed = value;
  }

  onToggle(value: boolean) {
    this.isCollapsed = value;
  }
}
