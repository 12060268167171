import { CommonModule, KeyValuePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { PrimengModule } from './primeng.module';

@NgModule({
  imports: [
    PasswordStrengthMeterModule.forRoot(),
    PrimengModule,
    ComponentsModule,
    DirectivesModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    PasswordStrengthMeterModule,
    PrimengModule,
    ComponentsModule,
    DirectivesModule
  ],
  providers: [KeyValuePipe]
})
export class SharedModule {}
