import { Component, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-private-footer',
  templateUrl: './private-footer.component.html',
  styleUrls: ['./private-footer.component.scss']
})
export class PrivateFooterComponent implements OnInit {
  get version() {
    return environment.version;
  }

  get year() {
    return new Date().getUTCFullYear();
  }

  constructor() {}

  ngOnInit() {}
}
