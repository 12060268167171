import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ProfanityService } from '@core/services';
import { profanityValidator } from '../validators/profanity.validator';
import { Subject, takeUntil } from 'rxjs';

export function setProfanityFilter(
  formControl: FormControl,
  profanityService: ProfanityService
): () => void {
  const stop$ = new Subject<void>();
  const existingValidators = formControl.validator;

  profanityService.getProfanityArr.pipe(takeUntil(stop$)).subscribe(badWords => {
    if (badWords.length) {
      const validators = existingValidators
        ? [profanityValidator(badWords), existingValidators]
        : [profanityValidator(badWords)];
      formControl.setValidators(validators);
    }
  });

  return () => {
    stop$.next();
    stop$.complete();
  };
}

export function applyProfanityFilterToControls(
  controlNames: string[],
  formGroup: FormGroup,
  profanityService: ProfanityService
): void {
  controlNames.forEach(controlName => {
    const formControl = formGroup.get(controlName) as FormControl;
    setProfanityFilter(formControl, profanityService);
  });
}
