<div class="privacy-policy-container">
  <p class="mt-4">
    At HIVVE GROUP LTD, we are committed to protecting personal data that we obtain about you. This
    Website Privacy Policy explains how we will process the data relating to you that you provide to
    us (or that we may collect from others) when you visit the following websites:
    <a rel="noopener noreferrer" target="_blank" href="https://{{companyUrl}}">hivve.tech</a>,
    <a rel="noopener noreferrer" target="_blank" href="https://www.{{impactTracker}}"
      >www.vvimpacttracker.com</a
    >,
    <a rel="noopener noreferrer" target="_blank" href="https://www.{{impactTrackerAu}}"
      >www.vvimpacttracker.com.au</a
    >,
    <a rel="noopener noreferrer" target="_blank" href="https://www.{{hivveImpactTracker}}"
      >www.hivveimpacttracker.com</a
    >,
    <a rel="noopener noreferrer" target="_blank" href="https://{{trackImpact}}"
      >www.trackimpact.org</a
    >
    and
    <a rel="noopener noreferrer" target="_blank" href="https://www.{{myVVid}}">www.myVVid.com</a>
    (the "Websites").
  </p>
  <p>
    The Websites are operated by HIVVE GROUP LTD (hivve, <b>we</b>, <b>us</b>, <b>our</b>), a
    company incorporated in England & Wales with company number 11093817 and registered office at
    Albany House, Georgian Heights, Bourne End, England SL8 5NJ, UK. Where we decide the purpose or
    means for the processing of the personal data that you or others provide via our Websites, we
    are the "data controller". As data controller, we will comply with all applicable data
    protection laws (including the General Data Protection Regulation 2016/679).
  </p>
  <p>
    This Website Privacy Policy forms part of our Website Terms of Use, which can be found
    <a rel="noopener noreferrer" target="_blank" href="https://{{companyTermsUrl}}">[here]</a>. We
    encourage all visitors to our Websites to read the Website Terms of Use and this policy in full.
    Additionally, if you are a client of hivve or if you will be using our services, we encourage
    you to read this policy as access to our services is provided via the Websites.
  </p>
  <p>
    [Our Websites may contain hyperlinks to third party websites. These websites operate fully
    independently from us, and we cannot accept any responsibility or liability for the privacy
    practices of such third parties nor the availability of these external sites or resources. The
    appearance of such links on our Websites is not an endorsement. Should you use any of these
    websites, such use is at your own risk and we would advise that you review their respective
    privacy policies.]
  </p>
  <p>
    If you have any questions or comments about this Website Privacy Policy, please let us know by
    emailing <a href="mailto:{{companyEmail}}">{{companyEmail}}</a>. Please note that we may update
    this policy from time to time.
  </p>
  <h6>What personal information are we likely to collect about you?</h6>
  <p>
    Not only do our Websites provide visitors with information about the services that we offer, but
    they also function as platforms providing our clients and other users access to our services,
    [as a means to upload user content or to view impact performance dashboards].
  </p>
  <p>
    In order to provide our online services, including our myVVid, ImpactTracker, ImpactAcademy and
    TrackImpact services, online engagements, such as interactive meetings and webinars, and other
    related business activities, we need to collect and process information about our Websites'
    visitors and, in particular, the users of our online services.
  </p>
  <h6><u>Information provided by you</u></h6>
  <p>When you use our Websites, you may be asked to fill in forms to create your own account.</p>
  <p class="mb-0">We will ask you to provide us with the following information:</p>
  <p class="mb-1">
    Please note * items listed below will be made visible for all hivve users and as part of our
    community profiles on
    <a rel="noopener noreferrer" target="_blank" href="https://{{trackImpact}}">TrackImpact.org</a>:
  </p>
  <ul>
    <li>First Name*</li>
    <li>Second Name*</li>
    <li>Job Title*</li>
    <li>Organisation or company name*</li>
    <li>Location (Country)*</li>
    <li>Username</li>
    <li>Email address* (if you choose to select it as your preferred contact method)</li>
    <li>Profile image* and banner image* (if you choose to provide one)</li>
    <li>Cell phone number* (if you choose to select WhatsApp as your preferred contact method)</li>
  </ul>
  <p class="mt-4 mb-1">
    If you decide to purchase any of our services, we will also need you to provide:
  </p>
  <ul>
    <li>your billing details other information</li>
  </ul>
  <p class="mt-4 mb-1">
    Additionally, in the course of using our Products, we will collect certain information about
    your activities and preferences when using this service, including:
  </p>
  <ul>
    <li>individuals that you follow</li>
    <li>your projects of interest</li>
    <li>
      any downloads you make of evidence information your communications and interactions with other
      users on the platform (including any requests to collaborate with other users)
    </li>
  </ul>
  <p class="mt-4">
    Our
    <a rel="noopener noreferrer" target="_blank" href="https://{{companyContactUrl}}"
      >["Contact Us"]</a
    >
    contact function also allows our Websites visitors to include a short message. Depending on your
    message, we may obtain additional personal data, which could include special categories of
    personal data (i.e. personal data of a more sensitive nature), when you voluntarily supply
    information through this function on our Websites.
  </p>
  <h6><u>Analytics</u></h6>
  <p>
    We are always trying to improve our Websites and to offer the best, most accessible service
    possible to all of our customers.
  </p>
  <p>
    To evaluate and make improvements, we utilise third party data analytics service providers (such
    as Google Analytics) to improve our visibility and to monitor website browser behaviour and
    navigation across the Websites. These third party data analytics service providers collect this
    information on our behalf in accordance with our instructions and in line with their own privacy
    policies. We have contracts in place to protect the confidentiality of your information with all
    of our third party data analytics service providers.
  </p>
  <p class="mb-1">
    When you visit our Websites, our service providers may collect the following data, which will
    almost always be anonymised and aggregated before reporting back to us:
  </p>
  <ul>
    <li>number of visitors to our Websites</li>
    <li>pages visited while at the Websites and time spent per page</li>
    <li>page interaction information, such as scrolling, clicks and browsing methods</li>
    <li>websites where visitors have come from and where they go afterwards</li>
    <li>page response times and any download errors</li>
    <li>other behavioural information about your visit to the Websites</li>
    <li>
      other technical information relating to end user device, such as IP address or browser plug-in
    </li>
  </ul>
  <p class="mt-4">
    To find out more about how this data is collected, please read below about the use of cookies,
    analytics and other tracking technology.
  </p>
  <h6>How do we use your personal data and on what basis?</h6>
  <p>
    When you visit our Websites, whether to browse our content, to contact us about our services, to
    purchase our services or to upload or view content, we (or our third party service providers
    acting on our behalf) may use the personal information that you provide for the following
    purposes:
  </p>
  <h6><u>Providing our Services</u></h6>
  <ul>
    <li>
      entering into an agreement with you to provide our services to you or that grants you the use
      of our services
    </li>
    <li>logging and processing your purchase order in our information systems</li>
    <li>providing a platform via which users may upload content</li>
    <li>
      providing online "delivery" of our services, such as access to project information, uploaded
      user content or dashboards
    </li>
  </ul>
  <p>
    If you are a client of hivve, we collect and use this information from you to enable us to
    perform our sales contract (for the supply of services) with you and to enable you to access
    online services in line with our
    <a rel="noopener noreferrer" target="_blank" href="https://{{companyTermsUrl}}"
      >Website Terms of Use</a
    >
    and our standard End User Licence Agreement (EULA).
  </p>
  <p>
    If you are an end user of our Services, we will only collect and use this information where an
    EULA is in place and such processing shall be done to enable us to perform under that contract.
  </p>
  <h6><u>Customer Services & Enquiries</u></h6>
  <ul>
    <li>replying to your enquiries or responding to concerns or complaints</li>
    <li>
      providing you with information about the services we offer, including our free e-newsletter
      and webinars, important announcements about your account, and other updates(where you have
      opted to receive these)
    </li>
    <li>sending you marketing materials</li>
  </ul>
  <p>
    If you contact us to make an enquiry or to raise a concern about our Websites or any aspect of
    our online services, we aim to respond to you as promptly as possible and we do so on the basis
    that we have a legitimate interest in replying to you.
  </p>
  <p>
    If you have asked to be added to our mailing list, we will provide your details to a third party
    company called HubSpot that helps us to produce and manage our newsletters and other updates. We
    provide these communications on the basis that you have asked to receive these. If you change
    your mind, you may opt-out at any time via the unsubscribe feature that appears in our emails or
    by emailing <a href="mailto:{{companyEmail}}">{{companyEmail}}</a>.
  </p>
  <p>
    You may also be asked to opt in to receive updates or information about other similar services
    from our network of recommended suppliers or to receive promotional material from us or our
    trusted third party suppliers.
  </p>
  <h6><u>Tax and other legal obligations</u></h6>
  <ul>
    <li>reporting VAT and other company tax reporting purposes (as required by law)</li>
    <li>
      complying with regulators, law enforcement agencies or in relation to any claims that may
      arise
    </li>
  </ul>
  <p>
    We process personal information for the legitimate interests and under the legal obligation of
    ensuring that use of the Websites (i) is lawful and non-fraudulent, (ii) does not disrupt the
    operation of our services, (iii) does not harass our staff or other individuals, and (iv) to
    enforce our legal rights and comply with our legal obligations.
  </p>
  <p>
    In accordance with our legal obligations, we may disclose your information to the extent
    required by law which may include for financial accounting and taxation purposes with our
    auditors, regulators or other government bodies such as HMRC. We may also disclose your
    information to third parties in order to enforce or apply our contractual terms, to investigate
    potential breaches, to protect the rights and freedoms, property or safety of our business or
    those of our clients, end users and other individuals.
  </p>
  <p>
    Where we reasonably believe that you are or may be in breach of any of the applicable laws, we
    may use your personal information to inform relevant third parties such as your email/internet
    provider or law enforcement agencies about the content.
  </p>
  <p>
    If we are involved in a merger, acquisition, or sale of all or a portion of our business or
    assets, the information we hold may be included as part of that sale, in which case you will be
    notified via email and/or a prominent notice on the relevant Website of any changes in ownership
    or use of your information, as any choices you may have regarding that information.
  </p>
  <h6><u>Cookies, Analytics & other Tracking Technology</u></h6>
  <p>
    Cookies: Our Websites use cookies and other mechanisms to collect log and analytical
    information, to help analyse how visitors use the site and to compile statistical reports on the
    activity of the Websites.
  </p>
  <p>What are Cookies?</p>
  <p>
    Cookies are files, often including unique identifiers, that are sent by web servers to web
    browsers, and which may then be sent back to the server each time the browser requests a page
    from the server. Cookies can be used by web servers to identify and track users as they navigate
    different pages on a website, and to identify users returning to a website.
  </p>
  <p>
    Cookies may be either "persistent" cookies or "session" cookies. A persistent cookie consists of
    a text file sent by a web server to a web browser, which will be stored by the browser and will
    remain valid until its set expiry date (unless deleted by the user before the expiry date). A
    session cookie, on the other hand, will expire at the end of the user session, when the web
    browser is closed.
  </p>
  <p>
    Cookies may also be either "first-party cookies" or "third-party cookies". First-party cookies
    are those that we set ourselves when you visit our Websites. Third-party cookies" are those set
    by another website (with a different domain from ours), such as by our trusted third party
    service providers or selected advertisers.
  </p>
  <p class="mb-1">How we use Cookies</p>
  <ul>
    <li>
      Cookies do not contain any information that personally identifies you, but personal
      information that we store about you may be linked, by us, to the information stored in and
      obtained from cookies. The cookies used on the Websites include those which are strictly
      necessary cookies for access and navigation, cookies that track usage (performance cookies),
      remember your choices (functionality cookies), and cookies that provide you with targeted
      content or advertising.
    </li>
    <li>
      We may use the information we obtain from your use of our cookies to recognise your computer
      when you visit the Websites, to improve the Websites' usability or to analyse the use of the
      Websites.
    </li>
    <li>
      You may also be sent third party cookies from our advertisers and third party service
      providers when you use the Websites, and they may use the information they obtain from your
      use of their cookies to track your browser across multiple websites, to build a profile of
      your web surfing and to target advertisements which may be of particular interest to you.
    </li>
  </ul>
  <p class="mt-4">
    If you would like to find out about how to manage cookies, including blocking or deleting
    cookies, you can visit
    <a rel="noopener noreferrer" target="_blank" href="www.allaboutcookies.org/manage-cookies"
      >www.allaboutcookies.org/manage-cookies</a
    >
    or contact us at <a href="mailto:{{companyEmail}}">{{companyEmail}}</a>.
  </p>
  <p>
    Analytics: Our Websites also use data analytics tools, such as Google Analytics. Google
    Analytics uses both session cookies and persistent cookies on the Websites.
  </p>
  <p>What does Analytics involve?</p>
  <p>
    You can find out more here:
    <a
      rel="noopener noreferrer"
      target="_blank"
      href="https://www.google.com/policies/privacy/partners/"
      >How Google uses data when you use our partners' sites or apps.</a
    >
  </p>
  <p>
    To learn how to opt out of Google Analytics, please visit
    <a rel="noopener noreferrer" target="_blank" href="https://tools.google.com/dlpage/gaoptout"
      >tools.google.com/dlpage/gaoptout</a
    >
    or your Google Ads Settings.
  </p>
  <p>How we use Data Analytics</p>
  <p>
    We process this information to understand how visitors use our Websites and to compile
    statistical reports regarding that activity (for example, your IP address is used to approximate
    the country from which you access our Websites, and we aggregate this information together so we
    know that, for example, most of the visitors to our Websites come from England).
  </p>
  <p>
    This processing is crucial to the running of our online business and we therefore undertake such
    monitoring in the pursuit of our legitimate interests in improving our Websites, and providing a
    better service and source of information to visitors. This information is not used to develop a
    personal profile of you.
  </p>
  <p>Financial Transactions</p>
  <p>
    All financial transactions relating to the purchase of our Services are handled directly by our
    payment service providers. We will not share any personal information with these third party
    providers, nor will we receive any of the financial information that you provide to them. The
    payment providers which we use are PayPal and Stripe.
  </p>
  <h6>Do we share your personal information with anyone else?</h6>
  <p>
    We will only ever share your information with third parties in the ways that are described in
    this Website Privacy Policy. If you would like to find out more about how those third parties
    listed in this policy use your information, this should be set out in their respective privacy
    policies accessible on their websites.
  </p>
  <h6>Personnel, Suppliers and Subcontractors</h6>
  <p>
    We keep your information confidential, but may disclose it to our personnel (including personnel
    in our group companies), suppliers or subcontractors (including our cloud-based data processing,
    data analytics and payment service providers) as well as to our trusted third party service
    providers insofar as it is reasonably necessary for the purposes set out in this policy,
    provided that they do not make independent use of the information and have agreed to adhere to
    the rules set out in this policy. In some instances, this data sharing may involve the transfer
    of information outside the EU. Please see our section on International Data Transfers below.
  </p>
  <p>
    [By group company, we mean our subsidiaries, our ultimate holding company and its subsidiaries,
    as defined with reference to the definitions of "holding undertaking" and "subsidiary
    undertaking" in section 1162 of the UK Companies Act 2006.]
  </p>
  <p class="mb-1">
    We will not pass your information on to third parties for marketing purposes unless you have
    provided your consent, in which event the advertisements that appear when you visit our Websites
    will be targeted to provide you with more relevant advertising content and you may receive
    communications from third parties offering similar or related services to us. Such third parties
    may include, without limitation:
  </p>
  <ul>
    <li>Microsoft</li>
    <li>Google</li>
    <li>HubSpot</li>
    <li>Amazon</li>
    <li>UserVoice</li>
    <li>GoToConnect</li>
    <li>Zoom</li>
    <li>Heart Internet</li>
  </ul>
  <h6 class="mt-4">
    Your choices and rights in relation to personal data which we process relating to you
  </h6>
  <p class="mb-1">
    You have the following rights over the way we process personal data relating to you. We aim to
    comply without undue delay, and within one month at the latest:
  </p>
  <ul>
    <li>to ask for a copy of data we are processing about you and have inaccuracies corrected;</li>
    <li>to ask us to restrict, stop processing, or to delete your personal data;</li>
    <li>
      to request a machine readable copy of your personal data, which you can use with another
      service provider. Where it is technically feasible, you can ask us to send this information
      directly to another provider if you prefer; and
    </li>
    <li>
      to make a complaint to a data protection regulator. You may contact them at:
      <a rel="noopener noreferrer" target="_blank" href="https://ico.org.uk/concerns"
        >ico.org.uk/concerns</a
      >
    </li>
    <li>
      to make a request in relation to any of the aforementioned rights, please email us at
      <a href="mailto:{{companyEmail}}">{{companyEmail}}</a>
    </li>
  </ul>
  <p class="mt-4">
    If you are unhappy with the way that we are processing your personal data, please let us know.
    The best way to bring this to our attention is by emailing us at
    <a href="mailto:{{companyEmail}}">{{companyEmail}}</a>.
  </p>
  <h6>International Data Transfers</h6>
  <p>
    Our servers are located in the European Union and the information that we collect directly from
    you will be stored in these servers. We may also transfer your personal data to our third party
    service providers, many of whom may be located outside of the EU, operate from multiple
    locations including non-EU based operations or engage sub-processors located outside the EU.
  </p>
  <p>
    We have agreements in place with all of our third party service providers to ensure that those
    parties process personal data using appropriate safeguards that meet the requirements of data
    protection laws. Such appropriate safeguards may include standard data protection clauses
    adopted by a data protection regulator and approved by the European Commission, such as the
    European Commission's standard contractual clauses.
  </p>
  <p>
    If you would like to find out more about these safeguards or if you have any other queries or
    comments in relation to this policy, please let us know by emailing us at
    <a href="mailto:{{companyEmail}}">{{companyEmail}}</a>.
  </p>
  <h6>Children</h6>
  <p>
    We do not knowingly use the Websites to solicit data from or market to children under the age of
    13.
  </p>
  <p>
    If a parent or guardian becomes aware that his or her child has provided us with information or
    may be receiving communications from us without consent of a parent or guardian, we ask that
    this be brought to our immediate attention. We will make it our priority to address this
    situation and delete information relating to a child as soon as practicable. In such an event,
    please contact us at <a href="mailto:{{companyEmail}}">{{companyEmail}}</a>.
  </p>
  <h6>Retention</h6>
  <p>
    In accordance with data protection laws and good commercial practice, we do not retain data in a
    form that permits identification of the person(s) to whom it relates for any longer than is
    necessary. Once the purpose for which information has been collected has been fulfilled, we will
    either permanently delete your personal information or remove all identifiers within it so that
    it is no longer personal data. We may use such anonymised data for research and/or business
    analysis purposes.
  </p>
  <p>
    Where you have provided us with personal information in order to set up an account with us, we
    will retain those details for as long as your account remains active.
  </p>
  <p>
    Where we obtain your personal data in relation to the use or purchase of our services or
    products, including VAT or invoicing information, we are obligated by law to keep this for a
    minimum of 6 years.
  </p>
  <p>
    Where you upload any user content, this content will be kept on our servers (or those of our
    third party service providers) for six months from the date that you upload it on our
    Websites/the duration of the relevant Services Agreement] and deleted thereafter unless
    otherwise notified.
  </p>
  <h6>Security</h6>
  <p>
    We will take commercially reasonable, appropriate technical and organisational measures to
    ensure a level of security appropriate to the risk that could be encountered via the use of our
    Websites and services, taking into account the likelihood and severity those risks might pose to
    the rights and freedoms of our customers and other individuals who might be impacted.
  </p>
  <p>
    In particular, we will take precautions to protect against the accidental or unlawful
    destruction, loss, or alteration, and unauthorised disclosure of or access to the personal data
    transmitted, stored or otherwise processed by us.
  </p>
  <p>
    Please be aware that, while we make the security of our Websites and your personal information a
    high priority and we devote considerable time and resources to implementing and maintaining
    robust information technology security, no security system can prevent all security breaches. By
    choosing to share your personal information with us, you accept the aforesaid and provide your
    information at your own risk.
  </p>
</div>
