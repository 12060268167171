import { Component, EventEmitter, Inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@core/services';
import { LegalService } from '@core/services/legal/legal.service';
import { DashboardComponent } from '@modules/dashboard/dashboard.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LegalComponent {
  showModal = false;

  @Input() showAcceptBtn!: boolean;
  @Output() acceptedEvent = new EventEmitter<boolean>();

  private stop$ = new Subject<void>();

  constructor(private authService: AuthService, private legalService: LegalService) {}

  onAccept() {
    this.legalService
      .acceptTerms()
      .pipe(takeUntil(this.stop$))
      .subscribe(() => {
        this.acceptedEvent.emit(true);
        this.showModal = false;
      });
  }

  async onClose() {
    this.showModal = false;

    if (!this.showAcceptBtn) {
      return true;
    }

    await this.authService.signOut(true);
    return false;
  }
}
