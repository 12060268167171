import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function matchControls(
  control: AbstractControl,
  matchControl: AbstractControl
): ValidatorFn {
  return (): ValidationErrors | null => {
    if (control.value !== matchControl.value) {
      matchControl.setErrors({ mismatchError: true });

      return null;
    }

    matchControl.setErrors(null);
    return null;
  };
}

export function shouldNotMatchControls(
  newPasswordControl: AbstractControl,
  oldPasswordControl: AbstractControl
): ValidatorFn {
  return (): ValidationErrors | null => {
    if (newPasswordControl.value === oldPasswordControl.value) {
      return { matchError: true };
    }

    return null;
  };
}
