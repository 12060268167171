<button
  [id]="id"
  class="btn basic w-100 {{classStyle}}"
  type="{{type}}"
  [disabled]="isDisabled || isLoading"
>
  <span *ngIf="isLoading" class="loader-text">
    <i class="vvicon spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></i>
    {{loadingText}}
  </span>
  <span *ngIf="!isLoading">
    <i *ngIf="icon" class="vvicon vvicon-{{icon}}"></i>
    {{text}}
  </span>
</button>
