import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftSideComponent } from './left-side/left-side.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PublicShellComponent } from './public-shell.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule, TranslateModule],
  declarations: [LeftSideComponent, PublicShellComponent]
})
export class PublicShellModule {}
