import { Injectable } from '@angular/core';
import { IToast } from '@core/models/toast.model';
import { Subject } from 'rxjs';

@Injectable()
export class ToastService {
  toastSource = new Subject<IToast[]>();
  toastObserver = this.toastSource.asObservable();

  constructor() {}

  addAll(toast: IToast[]): void {
    if (toast && toast.length) {
      this.toastSource.next(toast);
    }
  }
}
