import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../../auth.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<string>> {
    return next.handle(req).pipe(
      catchError(async err => {
        if (err.status === 401) {
          await this.authService.signOut(true);
        }

        const error = (err && err.error && err.error.message) || err.statusText;

        return error;
      })
    );
  }
}
