import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CognitoUserAttributes, CognitoVerificationAttributes } from '@core/enums';
import { AccountVerificationService, AuthService, IVerifyAttribute } from '@core/services';
import { lastValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-verification-modal',
  templateUrl: './mobile-verification-modal.component.html',
  styleUrls: ['./mobile-verification-modal.component.scss']
})
export class MobileVerificationModalComponent implements OnInit {
  verifyAttribute$!: Observable<IVerifyAttribute>;
  confirmCodeForm: FormGroup;
  codeControl: FormControl;
  attemptLimitError = 'Attempt limit exceeded, please try after some time.';
  invalidCodeError = 'Invalid verification code provided, please try again.';

  @Output() confirmEvent = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    private accountVerificationService: AccountVerificationService
  ) {
    this.confirmCodeForm = this.fb.group({
      code: (this.codeControl = this.fb.control('', Validators.required))
    });
  }

  ngOnInit(): void {}

  async sendAgain() {
    const response = await this.authService.resendSignUp(CognitoVerificationAttributes.Phone);

    if (response && response.error) {
      this.confirmCodeForm.setErrors({
        attemptLimit: this.attemptLimitError
      });
    }
  }

  async confirmCode(): Promise<void> {
    this.verifyAttribute$ = this.accountVerificationService.verifyAttribute(
      this.authService.accessToken || '',
      this.authService.idToken || '',
      this.codeControl.value,
      CognitoVerificationAttributes.Phone
    );

    if (this.confirmCodeForm.valid) {
      const result = await lastValueFrom(this.verifyAttribute$);

      if (result.success && this.authService.cognitoUser) {
        this.authService.cognitoUser.attributes.phone_number_verified = true;
      }

      if (result.error) {
        this.confirmCodeForm.setErrors({
          invalidCode: this.invalidCodeError
        });

        return;
      }

      this.authService.updateUserExistingAuthSessionAttribute({ phone_number_verified: true });
      this.confirmEvent.emit(true);
      this.confirmCodeForm.reset();

      /* Close the modal on confirmation success */
      document.getElementById('close-btn')?.click();
    }
  }

  onCancel() {
    this.confirmEvent.emit(false);
  }
}
