import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }

    if (req.url.includes('unsplash')) {
      const authReq = req.clone({
        headers: req.headers.set(this.AUTH_HEADER, `Client-ID ${environment.unsplashConfig.access}`)
      });

      return next.handle(authReq);
    }

    const jwtToken = this.authService.idToken;
    const authReq = req.clone({
      headers: req.headers.set(this.AUTH_HEADER, `Bearer ${jwtToken}`)
    });

    return next.handle(authReq);
  }
}
