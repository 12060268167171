import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Amplify } from '@aws-amplify/core';
import { APP_ROUTES, AUTH_KEYS } from '@core/constants';
import { IAppClient } from '@core/models';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class AppConfig {
  private _appClients!: IAppClient[];
  private _currentClientId = environment.awsCognitoConfig.amplify.Auth.userPoolWebClientId;

  get appClients(): IAppClient[] | undefined {
    return this._appClients;
  }

  get primaryAppClients(): IAppClient[] {
    let apps = this.appClients?.filter(x => x.attributes.primary_app) || [];
    apps.forEach(app => {
      let redirect_uris = app.redirect_uris;
      app.redirect_uris =
        location.hostname === 'localhost'
          ? redirect_uris.filter(uri => uri.includes('localhost'))
          : redirect_uris.filter(uri => !uri.includes('localhost'));
    });

    // filter out ImpactTracker+ in production
    if (environment.production) {
      apps = apps.filter(x => x.client_name !== 'ImpactTracker+');
    }

    apps = apps.filter(x => x.client_id !== this._currentClientId);
    return apps.sort((a, b) => Number(a.attributes.order) - Number(b.attributes.order));
  }

  constructor(private http: HttpClient) {}

  async initialize() {
    return new Promise<void>((resolve, reject) => {
      this.http.get<IAppClient[]>('/clients').subscribe({
        next: result => {
          this._appClients = result;
          resolve();
        },
        error: error => {
          console.error('Could not get clients. Server response: ' + JSON.stringify(error));
          reject();
        }
      });
    });
  }

  getClient(clientId: string): IAppClient | undefined {
    return this._appClients.find(client => client['client_id'] === clientId);
  }
}
