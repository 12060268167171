import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES, ERROR_MESSAGES } from '@core/constants';
import { environment } from '@env';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '../auth';
import { tap } from 'rxjs/operators';
import { CognitoUserAttributes } from '@core/enums';

@Injectable({
  providedIn: 'root'
})
export class LegalService {
  constructor(private http: HttpClient, private router: Router, private authService: AuthService) {}

  acceptTerms(): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}/uam-accept-user-td`, {}).pipe(
      tap(() => {
        this.authService.refreshSession();

        if (this.authService.cognitoUser) {
          this.authService.cognitoUser.attributes[CognitoUserAttributes.TermAccepted] = true;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.authService.signOut(false);
        this.router.navigate([APP_ROUTES.ERROR], {
          state: {
            error: ERROR_MESSAGES.SYSTEM_ERROR,
            route: APP_ROUTES.BASE,
            networkError: true
          }
        });

        return throwError(() => new Error(err.statusText));
      })
    );
  }
}
