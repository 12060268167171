import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  isUnderMaintenance = false;

  setUnderMaintenance(status: boolean): void {
    this.isUnderMaintenance = status;
  }

  isMaintenanceMode(): boolean {
    return this.isUnderMaintenance;
  }
}
