import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  delay: number = 0;

  private _loading = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly loading$ = this._loading.asObservable();

  set loading(value: boolean) {
    this._loading.next(value);
  }

  get loading(): boolean {
    return this._loading.value;
  }

  constructor() {}

  show() {
    this.loading = true;
  }

  hide() {
    // adding delay in http call use case
    // not to immediately stop the loader
    // while it has not redirected to a page
    setTimeout(() => {
      this.loading = false;
    }, Math.max(0, this.delay));
  }
}
