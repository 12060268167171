import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CognitoVerificationAttributes } from '@core/enums';
import { environment } from '@env';
import { Observable } from 'rxjs';

export interface IVerifyAttribute {
  success: boolean;
  error: boolean | string;
  code?: string;
}

export interface ISendVerificationCode {
  accessToken: string;
  error?: boolean | string;
  code?: string;
}

export interface ICheckAttributeVerification {
  verified: boolean;
  accessToken: string;
  error?: boolean | string;
  code?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountVerificationService {
  constructor(private http: HttpClient) {}

  /**
   *
   * @param userName Cognito account username
   * @param password Cognito account password
   * @param attribute Congito verification attribute
   * @param accessToken Congito verification accessToken. Not a mandatory field. If sent the lambda skips authentication step.
   * @description `Sends verification code to the cognito user contact attributes (email, phone).
   * If access Token is sent will skip authentication action`.
   */
  sendVerificationMessage(
    userName: string,
    password: string,
    attribute: string,
    accessToken?: string
  ): Observable<ISendVerificationCode> {
    return this.http.post<ISendVerificationCode>(
      `${environment.apiUrl}/send-verification-message`,
      {
        userName,
        password,
        attribute,
        accessToken
      }
    );
  }

  /**
   *
   * @param email Cognito account email
   * @param attribute Cognito verification attribute
   * @param accessToken Congito verification accessToken.
   * @description `Resends verification code to the cognito user contact attributes ("email", "phone_number").`
   */
  resendVerificationMessage(
    attribute: CognitoVerificationAttributes,
    accessToken?: string
  ): Observable<ISendVerificationCode> {
    return this.http.post<ISendVerificationCode>(
      `${environment.apiUrl}/send-verification-message`,
      {
        attribute,
        accessToken,
        resendCode: true
      }
    );
  }

  /*
   *
   * @description: `Verify user contact attributes`
   */
  verifyAttribute(
    accessToken: string,
    idToken: string,
    verificationCode: string,
    attribute: string
  ): Observable<IVerifyAttribute> {
    return this.http.post<IVerifyAttribute>(
      `${environment.apiUrl}/confirm-verification-attribute`,
      {
        idToken,
        accessToken,
        verificationCode,
        attribute
      }
    );
  }

  /**
   *
   * @param email string
   * @param password string
   * @param attribute string
   * @description `Check if user contact attributes are verified`
   */
  checkAttributeVerification(
    email: string,
    password: string,
    attribute: CognitoVerificationAttributes
  ): Observable<ICheckAttributeVerification> {
    return this.http.post<ICheckAttributeVerification>(
      `${environment.apiUrl}/check-attribute-verification`,
      {
        email,
        password,
        attribute
      }
    );
  }
}
