import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appScrollToDirective]',
  exportAs: 'appScrollToDirective'
})
export class ScrollToDirective {
  target!: HTMLElement | null;
  @Input() scrollToTarget!: string;

  constructor() {}

  @HostListener('click') onClick() {
    this.target = document.getElementById(this.scrollToTarget);

    setTimeout(() => {
      if (this.target) {
        this.target.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  }
}
