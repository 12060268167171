export enum SdgEnum {
  NO_POVERTY = '1',
  ZERO_HUNGER = '2',
  GOOD_HEALTH_AND_WELL_BEING = '3',
  QUALITY_EDUCATION = '4',
  GENDER_EQUALITY = '5',
  CLEAN_WATER_AND_SANITATION = '6',
  AFFORDABLE_AND_CLEAN_ENERGY = '7',
  DECENT_WORK_AND_ECONOMIC_GROWTH = '8',
  INDUSTRY_INNOVATION_AND_INFRASTRUCTURE = '9',
  REDUCED_INEQUALITIES = '10',
  SUSTAINABLE_CITIES_AND_COMMUNITIES = '11',
  RESPONSIBLE_CONSUMPTION_AND_PRODUCTION = '12',
  CLIMATE_ACTION = '13',
  LIFE_BELOW_WATER = '14',
  LIFE_ON_LAND = '15',
  PEACE_JISTICE_AND_STRONG_INSTITUTIONS = '16',
  PARTNERSHIPS_FOR_THE_GOALS = '17'
}
