import { NgModule } from '@angular/core';
import { AutoScrollDirective } from './auto-scroll';
import { ComponentLoaderDirective } from './component-loader/component-loader.directive';
import { CustomScrollDirective } from './custom-scroll';
import { FullContentDirective } from './full-content/full-content.directive';
import { ImageFallbackDirective } from './image-fallback/image-fallback.directive';
import { ScrollToDirective } from './scroll-to';
import { TogglePasswordDirective } from './toggle-password';
import { TrimInputDirective } from './trim-input';

@NgModule({
  declarations: [
    TogglePasswordDirective,
    ComponentLoaderDirective,
    CustomScrollDirective,
    AutoScrollDirective,
    FullContentDirective,
    ImageFallbackDirective,
    ScrollToDirective,
    TrimInputDirective
  ],
  exports: [
    TogglePasswordDirective,
    ComponentLoaderDirective,
    CustomScrollDirective,
    AutoScrollDirective,
    FullContentDirective,
    ImageFallbackDirective,
    ScrollToDirective,
    TrimInputDirective
  ]
})
export class DirectivesModule {}
