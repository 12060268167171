<div class="modal-dialog modal-dialog-centered" #modal>
  <div class="modal-content">
    <div class="pop-up_wrapper">
      <div
        *ngIf="!showChangeNumberView && !cognitoMobileNumber"
        class="update-number_wrapper mt-lg-2"
      >
        <app-change-number
          [missingMobileNumber]="true"
          (updateMobileNumber)="onUpdateMobileNumber($event)"
        ></app-change-number>

        <div class="text-center">
          <button
            id="add-cellphone-cancel-btn"
            type="button"
            class="btn borderless text-uppercase border-0 cancel-btn cancel-mobile-update"
            data-bs-dismiss="modal"
            (click)="onCancel()"
          >
            {{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.CANCEL" | translate }}
          </button>
        </div>
      </div>

      <div
        *ngIf="!showChangeNumberView && cognitoMobileNumber"
        class="update-number_wrapper mt-lg-2"
      >
        <p class="text-center header">
          {{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.REVIEW_NUMBER" | translate }}
        </p>
        <p class="text-center subheader">
          {{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.IS" | translate }} {{cognitoMobileNumber}} {{
          "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.MOBILE_NUMBER" | translate }}
        </p>

        <button
          type="submit"
          (click)="openMobileVerificationModal()"
          class="send-code-btn btn basic w-100 text-uppercase border-0 mb-3 d-inline-flex justify-content-center align-items-center"
        >
          <span class="text">{{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.SEND_CODE" | translate }}</span>
        </button>

        <div class="text-center">
          <a class="change-btn" href="javascript:void(null)" (click)="switchToUpdateMobileView()"
            >{{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.CHANGE_MY_NUMBER" | translate }}</a
          >
        </div>
        <div class="text-center">
          <button
            id="change-cellphone-cancel-btn"
            type="button"
            class="btn borderless text-uppercase border-0 cancel-mobile-update"
            data-bs-dismiss="modal"
            (click)="onCancel()"
          >
            {{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.CANCEL" | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="showChangeNumberView" class="change-number_wrapper mt-lg-2">
        <app-change-number
          [missingMobileNumber]="false"
          (updateMobileNumber)="onUpdateMobileNumber($event)"
        ></app-change-number>
        <div
          class="d-flex justify-content-center back"
          id="backButton"
          (click)="backToReviewNumber()"
        >
          <p class="text-uppercase">{{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.BACK" | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
