import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-password-strength-label',
  templateUrl: './password-strength-label.component.html',
  styleUrls: ['./password-strength-label.component.scss']
})
export class PasswordStrengthLabelComponent implements OnInit {
  @Input() score: number = 0;

  constructor() {}

  ngOnInit(): void {}
}
