import { IEnvironment } from '@core/models';

export const environment: IEnvironment = {
  name: 'dev',
  production: false,
  version: process.env['NG_APP_VERSION'] + '-dev',
  gstConfig: {
    trackingIds: [process.env['NG_APP_GST_TRACKING_ID']],
    anonymizeIp: true
  },
  awsCognitoConfig: {
    amplify: {
      Auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_iAbKQJy7I',
        userPoolWebClientId: '3mc5ek78ggdkh4vivjsmr8drbb',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        oauth: {
          domain: 'dev-myvvid.com',
          scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'https://dev-myvvid.com',
          redirectSignOut: 'https://dev-myvvid.com',
          responseType: 'code'
        }
      }
    },
    userConfirmation: {
      email: '',
      password: ''
    },
    forgotPasswordUsername: ''
  },
  apiUrl: '/uam-api',
  appAccessCode: process.env['NG_APP_APP_ACCESS_CODE'],
  recaptchaSiteKey: process.env['NG_APP_RECAPTCHA_SITE_KEY'],
  unsplashConfig: {
    access: process.env['NG_APP_UNSPLASH_ACCESS_KEY'],
    secret: process.env['NG_APP_UNSPLASH_SECRET_KEY']
  }
};
