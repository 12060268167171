import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { APP_ROUTES } from '@core/constants';
import { AuthService } from '..';
import { MaintenanceService } from '@core/services/maintenance';

@Injectable()
export class AuthorizeGuard implements CanActivate, CanActivateChild {
  constructor(
    private auth: AuthService,
    private router: Router,
    private maintenanceService: MaintenanceService
  ) {}

  canActivate(): boolean {
    return this.returnAuthorizedOrRedirect();
  }

  canActivateChild(): boolean {
    return this.returnAuthorizedOrRedirect();
  }

  private returnAuthorizedOrRedirect(): boolean {
    // Check if the application is in maintenance mode
    if (this.maintenanceService.isMaintenanceMode()) {
      this.router.navigate(['/maintenance']);
      return false;
    }

    if (!this.auth.isAuthenticated) {
      this.router.navigate([APP_ROUTES.BASE]);
      return false;
    }

    return true;
  }
}
