import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  AfterContentInit,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { ProfanityService } from '@core/services';
import { setProfanityFilter } from '@core/utils';
import { Editor } from 'primeng/editor';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class EditorComponent implements OnInit, ControlValueAccessor, AfterContentInit {
  @ViewChild('descriptionEditor') descriptionEditorRef!: Editor;
  @Input() header!: string;
  @Input() placeholder!: string;
  @Output() getBiographyText = new EventEmitter<string>();

  editorForm: FormGroup;
  descriptionFormControl!: FormControl;
  biographyText!: string;

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private profanityService: ProfanityService
  ) {
    this.editorForm = this.fb.group({
      descriptionControl: (this.descriptionFormControl = this.fb.control(null))
    });

    setProfanityFilter(this.descriptionFormControl, this.profanityService);
  }

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.changeDetectorRef.detectChanges();
    if (this.descriptionFormControl.value) {
      this.biographyText = this.descriptionEditorRef.el.nativeElement.innerText;
      this.getBiographyText.emit(this.biographyText);
    }
  }

  writeValue(acc: string): void {
    if (acc) {
      this.descriptionFormControl.setValue(acc);
    }
  }

  registerOnChange(fn: () => string): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => string): void {}

  handleTextChange(e: any): void {
    this.biographyText = e.textValue;
    this.getBiographyText.emit(this.biographyText);
    this.onChangeCallback(e.htmlValue);
  }

  hasErrors(): boolean {
    return this.descriptionFormControl.invalid;
  }

  private onChangeCallback: (_: string) => void = () => {};
}
