import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services';
import { LegalComponent } from '@shared/components/legal';
import { ComponentLoaderDirective } from '@shared/directives';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-legal-links',
  templateUrl: './legal-links.component.html',
  styleUrls: ['./legal-links.component.scss']
})
export class LegalLinksComponent implements OnInit, OnDestroy {
  @Input() showAcceptBtn!: boolean;
  @Output() acceptedEvent = new EventEmitter<boolean>();

  @ViewChild(ComponentLoaderDirective, { static: true })
  componentLoader!: ComponentLoaderDirective;
  baseUrl!: string;

  private stop$ = new Subject<void>();

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    // Note: there is a weird behavior from <a> href
    // when you do '/' won't be picked up the current route
    // when you use this component as a child in a component (e.g. dashboard)
    this.baseUrl = this.router.url;
    const base = this.baseUrl.split('#').shift();

    if (base) {
      this.baseUrl = base;
    }
  }

  ngOnDestroy(): void {
    this.componentLoader.viewContainerRef.clear();
    this.stop$.next();
    this.stop$.complete();
  }

  onAccept(accepted: boolean) {
    this.acceptedEvent.emit(accepted);
  }

  onOpen() {
    const component = this.componentLoader.viewContainerRef.createComponent(LegalComponent);
    component.instance.showModal = true;
    component.instance.showAcceptBtn = this.showAcceptBtn;

    component.instance.acceptedEvent.pipe(takeUntil(this.stop$)).subscribe((res: boolean) => {
      if (res) {
        this.acceptEvent(res);
      }
    });
  }

  acceptEvent(isAccepted: boolean): void {
    this.onAccept(isAccepted);
    this.showAcceptBtn = false;
  }
}
