<div class="i18n">
  <p-dropdown
    [options]="languages"
    styleClass="i18n-dropdown"
    [(ngModel)]="currentLocale"
    optionLabel="name"
    [filter]="true"
    filterBy="name"
    (onChange)="onChange($event)"
  >
    <ng-template pTemplate="selectedItem">
      <div class="locale-item locale-item-value w-100" *ngIf="currentLocale">
        <span class="flag-icon flag-icon-{{ currentLocale.code }}"></span>
        <span class="selected-item-text">{{ currentLocale.i18n | translate }}</span>
      </div>
    </ng-template>
    <ng-template let-language pTemplate="item">
      <div class="language-item w-100">
        <span class="flag-icon flag-icon-{{ language.code }}"></span>
        <span class="item-text">{{ language.i18n | translate}}</span>
      </div>
    </ng-template>
  </p-dropdown>
</div>
