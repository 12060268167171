import { Component, ViewEncapsulation } from '@angular/core';
import { ILanguage } from '@core/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageSelectorComponent {
  default: ILanguage = {
    name: 'English (UK)',
    i18n: 'LANGUAGE.English_UK',
    locale: 'en-GB',
    code: 'gb'
  };

  languages: ILanguage[] = [
    { name: 'Filipino', i18n: 'LANGUAGE.Filipino', locale: 'fil-PH', code: 'ph' },
    { name: 'Български', i18n: 'LANGUAGE.Bulgaria', locale: 'bg-BG', code: 'bg' },
    { name: 'English (UK)', i18n: 'LANGUAGE.English_UK', locale: 'en-GB', code: 'gb' },
    { name: 'English (US)', i18n: 'LANGUAGE.English_US', locale: 'en-US', code: 'us' },
    { name: 'Nederlands', i18n: 'LANGUAGE.Nederlands', locale: 'nl-NL', code: 'nl' }
  ];

  currentLocale: ILanguage;

  constructor(public translateService: TranslateService) {
    const currentLang = localStorage.getItem('locale') || translateService.currentLang;
    this.currentLocale = this.languages.find(x => x.locale == currentLang) || this.default;
    this.translateService.use(currentLang);
  }

  getLanguage(value: string) {
    const selected = this.languages.find(x => x.locale == value);
    return selected;
  }

  onChange(event: any) {
    this.translateService.setDefaultLang(event.value.locale);
    this.translateService.use(event.value.locale);
    localStorage.setItem('locale', event.value.locale);
  }
}
