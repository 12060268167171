import { Component, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  domainPrefix: string = 'dev-';

  myVVid = `myvvid.com`;
  trackImpact = `trackimpact.org`;
  impactTracker = `vvimpacttracker.com`;
  impactTrackerAu = `vvimpacttracker.com.au`;
  hivveImpactTracker = `hivveimpacttracker.com`;
  companyUrl = `hivve.tech`;
  companyEmail = 'hello@hivve.tech';
  companyContactUrl = `${this.companyUrl}/contact-us`;
  companyTermsUrl = `${this.companyUrl}/terms-and-conditions`;

  constructor() {}

  ngOnInit(): void {
    if (!environment.production) {
      this.domainPrefix = `${environment.name}-`;
    }

    this.myVVid = `${this.domainPrefix}myvvid.com`;
    this.trackImpact = `${this.domainPrefix}trackimpact.org`;
    this.impactTracker = `${this.domainPrefix}vvimpacttracker.com`;
    this.hivveImpactTracker = `${this.domainPrefix}hivveimpacttracker.com`;
  }
}
