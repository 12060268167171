<ng-container [formGroup]="recaptchaForm">
  <re-captcha
    (resolved)="resolved($event)"
    (error)="onError($event)"
    errorMode="handled"
    siteKey="{{ recaptchaSiteKey }}"
    formControlName="recaptcha"
  ></re-captcha>
</ng-container>

<!-- <ng-container [formGroup]="recaptchaForm">
  <re-captcha
    (resolved)="resolved($event)"
    errorMode="handled"
    siteKey="{{ recaptchaSiteKey }}"
    formControlName="recaptcha"
  ></re-captcha>
</ng-container> -->
