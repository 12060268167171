<div class="links">
  <!-- Commented out for now (as per ITPLUS-1664) -->
  <!-- <a
    href=""
    id="terms-conditions-page"
    class="terms-conditions"
    data-bs-toggle="modal"
    data-bs-target="#terms-conditions-pop-up"
    >Terms & Conditions</a
  > -->
  <a appAutoScroll href="{{baseUrl}}#eula" id="eula-link" class="eula" (click)="onOpen()"
    >{{ "FOOTER.LEGAL_LINKS.EULA" | translate }}</a
  >

  <a
    appAutoScroll
    href="{{baseUrl}}#privacy-policy"
    id="privacy-policy-link"
    class="privacy-policy"
    (click)="onOpen()"
    >Privacy Policy</a
  >
</div>

<ng-template appComponentLoader></ng-template>
