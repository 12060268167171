import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { IAvatar } from '@core/models';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() avatar!: IAvatar | null;
  @Input() styleClass!: string;

  isRound!: boolean | undefined;
  showInitials = false;
  hasError = false;

  initials!: string;
  randomBgColor: string | undefined;

  constructor() {}

  ngOnInit() {
    this.randomBgColor = `random-color-${Math.floor(Math.random() * 6) + 1}`;
  }

  ngOnChanges(): void {
    if (this.avatar) {
      const first = this.avatar.firstName?.trim().substring(0, 1) || '';
      const last = this.avatar.lastName?.trim().substring(0, 1) || '';
      this.initials = `${first}${last}`.toLocaleUpperCase() || '';

      this.showInitials = !this.avatar.url;
    }
  }

  onError(value: boolean) {
    this.hasError = value;
  }
}
