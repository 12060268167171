import { Component, Input } from '@angular/core';
import { Icon } from '@core/enums';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent {
  @Input() icon: Icon | undefined | string;
  @Input() styleClass: string | undefined;

  getStyleClass() {
    if (!this.styleClass) {
      this.styleClass = 'd-flex';
    }

    return `${this.styleClass} icon-box`;
  }

  getIconId(): string {
    return `#${this.icon}`;
  }

  getIconClass(): string {
    return `icon-${this.icon}`;
  }
}
