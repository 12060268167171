import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { PRODUCT_NAME } from '@core/constants';
import { Icon } from '@core/enums/icon.enums';
import { IAppClient } from '@core/models';
import { IAccountProfile } from '@core/models/account-profile.model';
import { AccountService, AuthService } from '@core/services';
import { AuthRedirectionService } from '@core/services/auth/auth-redirection.service';
import { environment } from '@env';
import { Subject, takeUntil } from 'rxjs';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-private-top-navbar',
  templateUrl: './private-top-navbar.component.html',
  styleUrls: ['./private-top-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivateTopNavbarComponent implements OnInit {
  Icon = Icon;
  environment = environment;
  apps: IAppClient[];
  acountProfile!: IAccountProfile;

  @Input() isCollapsed = false;
  @Output() toggleEvent = new EventEmitter<boolean>();

  private MIN_WIDTH_SIZE = 576;
  private MAX_WIDTH_SIZE = 991.98;
  private stop$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private authRedirect: AuthRedirectionService,
    private config: AppConfig,
    private accountService: AccountService
  ) {
    this.apps = config.primaryAppClients;
  }

  ngOnInit(): void {
    this.accountService.getUserDetails.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        this.acountProfile = res;
      }
    });
  }

  redirect(app: IAppClient) {
    return this.authRedirect.redirectToApp(app);
  }

  async signOut(): Promise<void> {
    await this.authService.signOut(true);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const width = event.target.innerWidth;
    if (width && width >= this.MIN_WIDTH_SIZE && width <= this.MAX_WIDTH_SIZE) {
      this.isCollapsed = true;
      this.toggleEvent.emit(this.isCollapsed);
    }
  }

  onToggle() {
    this.isCollapsed = !this.isCollapsed;
    this.toggleEvent.emit(this.isCollapsed);
  }

  // disableAppForNonLicencedAccount(appClient: IAppClient): boolean {
  //   if (appClient.client_name === PRODUCT_NAME.IMPACT_TRACKER && !this.acountProfile?.isLicensed) {
  //     return true;
  //   }

  //   return false;
  // }
}
