import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IModalDetails } from '@core/models/modal.model';

@Component({
  selector: 'app-confirmation-message-pop-up',
  templateUrl: './confirmation-message-pop-up.component.html',
  styleUrls: ['./confirmation-message-pop-up.component.scss']
})
export class ConfirmationMessagePopUpComponent {
  // Input class added in order to be able to style the modal depending on your needs.
  @Input() inputClass!: string;
  @Input() messageIcon!: string;
  @Input() popUpDetails!: IModalDetails;

  constructor(private router: Router) {}

  next(): void {
    this.router.navigate([this.popUpDetails?.path]);
  }
}
