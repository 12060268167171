<div class="modal-dialog modal-dialog-centered" #modal>
  <div class="modal-content">
    <div class="pop-up_wrapper">
      <form [formGroup]="confirmCodeForm" class="w-100">
        <div class="confirm-code_wrapper mt-lg-2">
          <p class="text-center header">
            {{ "MOBILE_NUMBER.MOBILE_VERIFICATION_MODAL.CONFIRM_CELL" | translate }}
          </p>
          <p class="text-center subheader">
            {{ "MOBILE_NUMBER.MOBILE_VERIFICATION_MODAL.SENT_CODE" | translate }}
          </p>
          <div class="d-flex justify-content-center my-3"><div class="picture"></div></div>

          <div class="mb-3 mt-5 form-group">
            <input
              type="text"
              class="form-control confirm-code_textbox code"
              placeholder="Enter code"
              formControlName="code"
              [ngClass]="{
              'error-validation-field': codeControl?.errors && codeControl.dirty
            }"
            />
          </div>
          <span class="error-message" *ngIf="confirmCodeForm.errors?.['invalidCode']"
            >{{ confirmCodeForm.errors?.['invalidCode'] }}</span
          >
          <span class="error-message" *ngIf="confirmCodeForm.errors?.['attemptLimit']"
            >{{ confirmCodeForm.errors?.['attemptLimit'] }}</span
          >

          <!-- If this error message element is shown from other component,
          we must hide it if confirmCodeForm has errors in order not to appear two
          error message html element -->
          <span
            *ngIf="!confirmCodeForm.errors"
            id="verification-popup-error"
            class="error-message d-none"
            >{{attemptLimitError}}</span
          >

          <div class="send-again mb-5">
            <div class="mx-1 envelop-open"></div>
            <span
              >{{ "MOBILE_NUMBER.MOBILE_VERIFICATION_MODAL.DID_NOT_GET_CODE" | translate }}</span
            >
            <a href="javascript:void(null)" (click)="sendAgain()"
              >{{ "MOBILE_NUMBER.MOBILE_VERIFICATION_MODAL.SEND_AGAIN" | translate }}</a
            >
          </div>

          <button
            type="submit"
            (click)="confirmCode()"
            class="btn basic w-100 text-uppercase border-0 mb-3 d-inline-flex justify-content-center align-items-center"
            [disabled]="!confirmCodeForm?.valid"
          >
            <span class="text"
              >{{ "MOBILE_NUMBER.MOBILE_VERIFICATION_MODAL.CONFIRM" | translate }}</span
            >
          </button>

          <div class="text-center mb-5">
            <button
              data-bs-dismiss="modal"
              id="close-btn"
              type="button"
              hidden
              data-bs-dismiss="modal"
            ></button>

            <button
              id="cancel-btn"
              type="button"
              class="btn borderless text-uppercase border-0 cancel-btn"
              data-bs-dismiss="modal"
              (click)="onCancel()"
            >
              {{ "MOBILE_NUMBER.MOBILE_VERIFICATION_MODAL.CANCEL" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
