export const AUTH_KEYS = {
  ID_TOKEN: 'id_token',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  REDIRECT_URI: 'redirect_uri',
  CLIENT_ID: 'client_id',
  AUTHORIZATION_CODE: 'authorization_code',
  RESPONSE_TYPE: 'response_type',
  STATE: 'state',
  CODE: 'code'
};

export const AUTH_STORAGE_KEYS = {
  CLIENT_REDIRECT_URI: 'client-redirect-uri',
  CLIENT_STATE: 'client-state',
  REDIRECT: 'redirect'
};
