export enum CognitoErrorTypes {
  UserLambdaValidation = 'UserLambdaValidationException',
  UserNotConfirmed = 'UserNotConfirmedException',
  UsernameExists = 'UsernameExistsException',
  UserNotFound = 'UserNotFoundException',
  NotAuthorized = 'NotAuthorizedException',
  NetworkError = 'NetworkError',
  LimitExceededException = 'LimitExceededException'
}

export enum CognitoErrorMessages {
  LoginAttemptsExceeded = 'Password attempts exceeded'
}

export enum CognitoVerificationAttributes {
  Email = 'email',
  Phone = 'phone_number'
}

export enum CognitoUserAttributes {
  Email = 'email',
  EmailVerified = 'email_verified',
  FamilyName = 'family_name',
  Name = 'name',
  Phone = 'phone_number',
  PhoneNumberVerified = 'phone_number_verified',
  Sub = 'sub',
  TermAccepted = 'custom:termsAccepted'
}
