import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  OnDestroy,
  ElementRef,
  Input
} from '@angular/core';
import { Icon } from '@core/enums';
import { IUnsplashPhoto, IUnsplashSearchResult } from '@core/models';
import { IFileModel, IImageUploadData } from '@core/models/account-profile.model';
import { AccountService } from '@core/services';
import { ImageCroppedEvent, ImageCropperComponent, base64ToFile } from 'ngx-image-cropper';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit, OnDestroy {
  @ViewChild('cropper') cropper: ImageCropperComponent | undefined;
  @ViewChild('term') term!: ElementRef<HTMLInputElement>;

  @Input() unsplashImages: IUnsplashPhoto[] = [];
  @Input() updateBannerImage!: boolean;
  @Output() setImage = new EventEmitter<IImageUploadData>();

  Icon = Icon;
  isImageSelected: boolean = false;
  imageChangedEvent: Event | any;
  croppedImage: ImageCroppedEvent | any;
  imageFileDetails: Blob | undefined;
  imageFile!: Blob | any;
  imageURL!: string | undefined;
  showUnsplashImages: boolean = false;
  selectedUnsplashImage!: IUnsplashPhoto | any;
  selectedImageIndex: number = -1;
  searchValue: string = '';

  private stop$ = new Subject<void>();

  constructor(private accountService: AccountService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  // Browse file
  fileChangeEvent(event: Event): void {
    this.imageFile = null;
    this.imageURL = '';
    this.imageChangedEvent = event;
    this.isImageSelected = true;
  }

  // Cropped image
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageFileDetails = base64ToFile(this.croppedImage);
  }

  setInitialValue(): void {
    this.isImageSelected = false;
    this.showUnsplashImages = false;
    this.selectedImageIndex = -1;
    this.selectedUnsplashImage = {};
  }

  close() {
    this.searchValue = '';
    this.setInitialValue();
  }

  upload() {
    const imageFilePayload: IFileModel = {
      content: this.croppedImage,
      contentType: this.imageFileDetails?.type,
      size: this.imageFileDetails?.size
    };

    const imageUploadData: IImageUploadData = {
      fileData: imageFilePayload,
      userData: this.selectedUnsplashImage?.user || {}
    };

    this.setImage.emit(imageUploadData);

    this.isImageSelected = false;
    this.close();
  }

  // Handle file drop event
  handleDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.imageChangedEvent = null;
    this.imageURL = '';

    if (event.dataTransfer) {
      const files = event.dataTransfer.files;

      if (files.length > 0) {
        this.imageFile = files[0];
        this.isImageSelected = true;
      }
    }
  }

  allowDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  // Unsplash methods - load, select, search and choose unsplash image
  loadUnsplashPhotos(): void {
    this.showUnsplashImages = true;
  }

  selectUnsplashPhoto(image: IUnsplashPhoto, imageIndex: number): void {
    this.selectedUnsplashImage = image;

    if (this.selectedImageIndex === imageIndex) {
      this.selectedImageIndex = -1;
    } else {
      this.selectedImageIndex = imageIndex;
    }
  }

  chooseUnsplashPhoto(): void {
    this.imageChangedEvent = null;
    this.imageURL = this.selectedUnsplashImage.urls?.regular;
    this.isImageSelected = true;
    this.showUnsplashImages = false;
    this.selectedImageIndex = -1;
  }

  getUnsplashPhotos(): void {
    this.accountService
      .getUnsplashPhotos()
      .pipe(takeUntil(this.stop$))
      .subscribe((photos: IUnsplashPhoto[]) => {
        this.unsplashImages = photos;
      });
  }

  searchUnsplashPhotos(term: string): void {
    this.searchValue = term;
    this.selectedImageIndex = -1;

    if (!term) {
      this.getUnsplashPhotos();

      return;
    }

    this.accountService
      .getUnsplashSearchPhotos(term)
      .pipe(takeUntil(this.stop$))
      .subscribe((searchResult: IUnsplashSearchResult) => {
        this.unsplashImages = searchResult.results;
      });
  }
}
