<div class="password-strength-container" [class.add-margin]="isAddMargin">
  <div
    class="characters-label"
    [class.charcoal-label]="(passwordControl.errors?.['minlength'] || passwordControl.errors?.['maxlength']) && !toggleErrorColor"
    [class.red-label]="(passwordControl.errors?.['minlength'] || passwordControl.errors?.['maxlength'] || passwordControl.errors?.['required']) && toggleErrorColor"
    [class.green-label]="password.length && !passwordControl.errors?.['minlength'] && !passwordControl.errors?.['maxlength']"
  >
    {{ password.length }} {{ charactersLabel }}
  </div>
  <div class="password-requirement-labels">
    <div class="wrapper d-flex flex-column">
      <div class="container p-0">
        <div class="row mb-2">
          <div class="col d-flex align-items-baseline">
            <div
              *ngIf="(passwordControl.errors?.['invalidLowerCase'] || !password.length) && !toggleErrorColor"
              class="icon icon-x-gray"
            ></div>
            <div
              *ngIf="(passwordControl.errors?.['invalidLowerCase'] || passwordControl.errors?.['required']) && toggleErrorColor"
              class="icon icon-x-red"
            ></div>
            <div
              *ngIf="password.length && !passwordControl.errors?.['invalidLowerCase']"
              class="icon icon-check-green"
            ></div>
            <div
              [class.charcoal-label]="passwordControl.errors?.['invalidLowerCase'] && !toggleErrorColor"
              [class.red-label]="(passwordControl.errors?.['invalidLowerCase'] || passwordControl.errors?.['required']) && toggleErrorColor"
              [class.green-label]="password.length && !passwordControl.errors?.['invalidLowerCase']"
              [id]="(passwordControl.errors?.['invalidLowerCase'] || passwordControl.errors?.['required']) && toggleErrorColor ? 'red-label-lowercase' : 'green-label-lowercase'"
            >
              {{ "PASSWORD_STRENGTH_METER.LOWERCASE" | translate }}
            </div>
          </div>
          <div class="col d-flex align-items-baseline">
            <div
              *ngIf="(passwordControl.errors?.['invalidNumberDigit'] || !password.length) && !toggleErrorColor"
              class="icon icon-x-gray"
            ></div>
            <div
              *ngIf="(passwordControl.errors?.['invalidNumberDigit'] || passwordControl.errors?.['required']) && toggleErrorColor"
              class="icon icon-x-red"
            ></div>
            <div
              *ngIf="password.length && !passwordControl.errors?.['invalidNumberDigit']"
              class="password.length && icon icon-check-green"
            ></div>

            <div
              [class.charcoal-label]="passwordControl.errors?.['invalidNumberDigit'] && !toggleErrorColor"
              [class.red-label]="(passwordControl.errors?.['invalidNumberDigit'] || passwordControl.errors?.['required']) && toggleErrorColor"
              [class.green-label]="password.length && !passwordControl.errors?.['invalidNumberDigit']"
              [id]="(passwordControl.errors?.['invalidNumberDigit'] || passwordControl.errors?.['required']) && toggleErrorColor ? 'red-label-number-digit' : 'green-label-number-digit'"
            >
              {{ "PASSWORD_STRENGTH_METER.NUMBERS" | translate }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex align-items-baseline">
            <div
              *ngIf="(passwordControl.errors?.['invalidUpperCase'] || !password.length) && !toggleErrorColor"
              class="icon icon-x-gray"
            ></div>
            <div
              *ngIf="(passwordControl.errors?.['invalidUpperCase'] || passwordControl.errors?.['required']) && toggleErrorColor"
              class="icon icon-x-red"
            ></div>
            <div
              *ngIf="password.length && !passwordControl.errors?.['invalidUpperCase']"
              class="icon icon-check-green"
            ></div>
            <div
              [class.charcoal-label]="passwordControl.errors?.['invalidUpperCase'] && !toggleErrorColor"
              [class.red-label]="(passwordControl.errors?.['invalidUpperCase'] || passwordControl.errors?.['required']) && toggleErrorColor"
              [class.green-label]="password.length && !passwordControl.errors?.['invalidUpperCase']"
              [id]="(passwordControl.errors?.['invalidUpperCase'] || passwordControl.errors?.['required']) && toggleErrorColor ? 'red-label-uppercase' : 'green-label-uppercase'"
            >
              {{ "PASSWORD_STRENGTH_METER.UPPERCASE" | translate }}
            </div>
          </div>
          <div class="col d-flex align-items-baseline">
            <div
              *ngIf="(passwordControl.errors?.['invalidSpecialCharacter'] || !password.length) && !toggleErrorColor"
              class="icon icon-x-gray"
            ></div>
            <div
              *ngIf="(passwordControl.errors?.['invalidSpecialCharacter'] || passwordControl.errors?.['required']) && toggleErrorColor"
              class="icon icon-x-red"
            ></div>
            <div
              *ngIf="password.length && !passwordControl.errors?.['invalidSpecialCharacter']"
              class="icon icon-check-green"
            ></div>

            <div
              [class.charcoal-label]="passwordControl.errors?.['invalidSpecialCharacter'] && !toggleErrorColor"
              [class.red-label]="(passwordControl.errors?.['invalidSpecialCharacter'] || passwordControl.errors?.['required']) && toggleErrorColor"
              [class.green-label]="password.length && !passwordControl.errors?.['invalidSpecialCharacter']"
              [id]="(passwordControl.errors?.['invalidSpecialCharacter'] || passwordControl.errors?.['required']) && toggleErrorColor ? 'red-label-special-char' : 'green-label-special-char'"
            >
              {{ "PASSWORD_STRENGTH_METER.SYMBOLS" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
