import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@env';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { APP_ROUTES, PAGE_TITLES } from '@core/constants';
import { AuthService, Logger } from '@core/services';
import { MaintenanceService } from '@core/services/maintenance';

const log = new Logger('App');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private autoLogin!: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    // do not remove the analytics injection, even if the call in ngOnInit() is removed
    // this injection initializes page tracking through the router
    private angulartics: Angulartics2GoogleGlobalSiteTag,
    private titleService: Title,
    private maintenanceService: MaintenanceService
  ) {
    const queryStringParams = new URLSearchParams(window.location.search);
    this.autoLogin = !!queryStringParams.get('autologin');
  }

  ngOnInit(): void {
    // Set under maintanence page active if it necessary
    this.maintenanceService.setUnderMaintenance(false);

    if (environment.production) {
      Logger.enableProductionMode();
    }

    if (this.autoLogin && !this.authService.isAuthenticated) {
      this.authService.cognitoSignIn();
    }

    this.angulartics.startTracking();
    this.angulartics.eventTrack(environment.version || 'dev', {
      category: 'App initialized'
    });

    const onNavigationEnd = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    merge(onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data)
      )
      .subscribe(event => {
        const title = event['title']
          ? `${PAGE_TITLES.DEFAULT} - `.concat(event['title'])
          : event['title'];

        this.titleService.setTitle(title);
      });
  }
}
