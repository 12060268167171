import { NgModule } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { EditorModule } from 'primeng/editor';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  imports: [
    AutoCompleteModule,
    DialogModule,
    DropdownModule,
    TooltipModule,
    ScrollPanelModule,
    InputSwitchModule,
    EditorModule,
    OverlayPanelModule
  ],
  exports: [
    AutoCompleteModule,
    DialogModule,
    DropdownModule,
    TooltipModule,
    ScrollPanelModule,
    InputSwitchModule,
    EditorModule,
    OverlayPanelModule
  ]
})
export class PrimengModule {}
