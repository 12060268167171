import { Injectable } from '@angular/core';
import { IAppClient } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class AuthRedirectionService {
  constructor() {}

  async redirectToApp(app: IAppClient): Promise<Window> {
    return this.urlOpener(app.redirect_uris[0]);
  }

  redirectTo(url: string | null, params?: URLSearchParams | string): void {
    let redirectUrl = url;

    if (params) {
      redirectUrl = `${url}/?${params}`;
    }

    return window.location.replace(`${redirectUrl}`);
  }

  urlOpener(url: string, target: string = '_self'): Promise<Window> {
    const windowProxy = window.open(url, target);
    if (windowProxy) {
      return Promise.resolve(windowProxy);
    } else {
      return Promise.reject();
    }
  }
}
