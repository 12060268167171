import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appCustomScroll]',
  exportAs: 'appCustomScroll'
})
export class CustomScrollDirective {
  disableBtn = true;
  top!: number;
  offSetHeight!: number;
  scrollHeight!: number;
  bottomOffset = 20; // px

  constructor(private eleRef: ElementRef) {}

  @HostListener('scroll') onScrollEvent() {
    this.top = this.eleRef.nativeElement.scrollTop;
    this.offSetHeight = this.eleRef.nativeElement.offsetHeight;
    this.scrollHeight = this.eleRef.nativeElement.scrollHeight;

    if (this.top > this.scrollHeight - this.offSetHeight - this.bottomOffset) {
      this.disableBtn = false;
    }
  }
}
