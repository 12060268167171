import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAccountProfile,
  IAccountProfilePayload,
  IAccountUniqueProperties
} from '@core/models/account-profile.model';
import { environment } from '@env';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { IAccountOrganisation } from '../../models/auth.model';
import { ToastService } from '../toast';
import { AnyAaaaRecord } from 'dns';
import { getErrorMessageByActionType, getSuccessMessageByActionType } from '@core/utils';
import { IUnsplashPhoto, IUnsplashSearchResult } from '@core/models';
import { UNSPLASH_API_URL } from '@core/constants';

export interface IUpdateAccountResponse {
  statusCode: number;
  error?: any;
}

export interface IAccountUniquenessPropertyResponse {
  statusCode: number;
  error?: any;
  isUnique: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private _userDetails: BehaviorSubject<IAccountProfile | null> =
    new BehaviorSubject<IAccountProfile | null>(null);

  private _allOrganiztions: BehaviorSubject<IAccountOrganisation[]> = new BehaviorSubject<
    IAccountOrganisation[]
  >([]);

  get getUserDetails(): Observable<IAccountProfile | null> {
    return this._userDetails.asObservable();
  }

  set setUserDetails(value: IAccountProfile | null) {
    const currentValue = this._userDetails.getValue();
    const newValue = value ? { ...currentValue, ...value } : null;
    this._userDetails.next(newValue);
  }

  get getOrganizations(): Observable<IAccountOrganisation[]> {
    return this._allOrganiztions.asObservable();
  }

  set setOrganizations(value: IAccountOrganisation[]) {
    this._allOrganiztions.next(value);
  }

  constructor(private http: HttpClient, private toastService: ToastService) {}

  /**
   * @description `Get all organizations`
   */
  getAllOrganizations(): Observable<IAccountOrganisation[]> {
    return this.http.get<IAccountOrganisation[]>(
      `${environment.apiUrl}/uam-get-organizations-dropdown`
    );
  }

  /**
   * @description `Updates mobile number in uam database, cognito and track impact database`
   */
  updateMobileNumber(phoneNumber: string): Observable<IUpdateAccountResponse> {
    return this.http.post<IUpdateAccountResponse>(`${environment.apiUrl}/update-mobile-number`, {
      phoneNumber
    });
  }

  /**
   * @description `Updates password column in the UAM database`
   */
  updatePassword(password: string): Observable<IUpdateAccountResponse> {
    return this.http.post<IUpdateAccountResponse>(`${environment.apiUrl}/update-password`, {
      password
    });
  }

  /**
   * @description `Load account profile details`
   */
  loadAccountProfileDetails(): Observable<IAccountProfile> {
    return this.http.post<IAccountProfile>(`${environment.apiUrl}/uam-get-user`, {});
  }

  /**
   * @description `Update account preferences details`
   */
  updateAccountProfileDetails(payload: IAccountProfilePayload): Observable<IUpdateAccountResponse> {
    return this.http
      .post<IUpdateAccountResponse>(`${environment.apiUrl}/uam-update-user`, payload)
      .pipe(
        map(response => {
          let details = { severity: 'success', text: getSuccessMessageByActionType(payload) };

          if (response.statusCode !== 200) {
            details = { severity: 'error', text: getErrorMessageByActionType(payload) };
          }

          this.toastService.addAll([details]);

          return response;
        }),
        catchError(error => {
          this.toastService.addAll([
            {
              severity: 'error',
              text: getErrorMessageByActionType(payload)
            }
          ]);

          return of(error);
        })
      );
  }

  /**
   * @description `Check if payload property value is unique`
   */
  checkPropertyUniqueness(
    payload: IAccountUniqueProperties
  ): Observable<IAccountUniquenessPropertyResponse> {
    return this.http.post<IAccountUniquenessPropertyResponse>(
      `${environment.apiUrl}/check-unique-property`,
      payload
    );
  }

  /**
   * @description `Verify and update emailPrimary/preferredUsername/mobileNumber`
   */
  updateAccountSecurityProperty(
    payload: IAccountProfilePayload
  ): Observable<IUpdateAccountResponse> {
    return this.http.post<IUpdateAccountResponse>(`${environment.apiUrl}/uam-update-user`, payload);
  }

  /**
   * @description `Make an unsplash api request for listing photos`
   */
  getUnsplashPhotos(): Observable<IUnsplashPhoto[]> {
    return this.http.get<IUnsplashPhoto[]>(UNSPLASH_API_URL.PHOTOS);
  }

  /**
   * @description `Make an unsplash search api request by term`
   */
  getUnsplashSearchPhotos(term: string): Observable<IUnsplashSearchResult> {
    return this.http.get<IUnsplashSearchResult>(UNSPLASH_API_URL.SEARCH_PHOTOS, {
      params: { query: term }
    });
  }
}
