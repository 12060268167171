<div class="left-side_container d-flex flex-column justify-content-around">
  <div class="left-side_vv-logo-container d-lg-flex justify-content-center">
    <div class="vv-logo"></div>
  </div>
  <div class="left-side_map-container">
    <div class="vvid-map"></div>
    <div class="vvid-social"></div>
  </div>
  <ng-container *ngIf="clientLogo">
    <div class="left-side-app-logo">
      <img title="logo" [ngClass]="{'img-ti' : clientName === 'TrackImpact'}" [src]="clientLogo" />
    </div>
  </ng-container>
  <div class="left-side-verbiage-container d-flex flex-column justify-content-center">
    <div class="verbiage text-center">
      Be part of the global community that uses hivve’s products to capture, measure and report on
      their impact to create a more sustainable world
    </div>

    <div class="terms-conditions text-center">
      <app-legal-links></app-legal-links>
      <app-copyright></app-copyright>
    </div>
  </div>
</div>
