import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function profanityValidator(badWords: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputValue: string = control.value || '';

    const hasBadWords = badWords.some(badWord => {
      const escapedBadWord = badWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const pattern = new RegExp(`(?<![\\w\\d])${escapedBadWord}(?![\\w\\d])`, 'i');
      return pattern.test(inputValue);
    });

    return hasBadWords ? { badWords: true } : null;
  };
}
