import { NgModule } from '@angular/core';
import { PrivateShellComponent } from './private-shell.component';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { PrivateLeftSidebarComponent } from './private-left-sidebar/private-left-sidebar.component';
import { PrivateFooterComponent } from './private-footer/private-footer.component';
import { PrivateTopNavbarComponent } from './private-top-navbar/private-top-navbar.component';

@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [
    PrivateLeftSidebarComponent,
    PrivateTopNavbarComponent,
    PrivateFooterComponent,
    PrivateShellComponent
  ]
})
export class PrivateShellModule {}
