<p class="text-center header">{{headerText}}</p>
<p class="text-center subheader" [innerHTML]="subheaderText"></p>

<form [formGroup]="updateMobileNumberForm" class="w-100">
  <div class="dropdown-fields dropdown-fields-phone-message">
    <div
      class="form-group dropdown-fields-box"
      [ngClass]="{
                    'error-validation-field':
                      (!mobileNumberCodeControl.value && mobileNumberCodeControl.touched) ||
                      (mobileNumberControl.errors && mobileNumberControl.dirty) ||
                      (!mobileNumberCodeControl.value && mobileNumberControl.value) ||
                      (mobileNumberCodeControl.value && !mobileNumberControl.value)
                  }"
    >
      <div class="row g-0 phone-fields">
        <div class="col-lg-3 col-md-3 col-sm-3 col-4 phone-code">
          <div class="dropdown">
            <span
              *ngIf="!mobileNumberRegionCode"
              class="selected-country-flag flag-icon flag-icon-{{this.defaultPhoneCode.countryCode.toLowerCase()}}"
            ></span>
            <span
              *ngIf="mobileNumberRegionCode"
              class="selected-country-flag flag-icon flag-icon-{{
                            mobileNumberRegionCode.toLowerCase()
                          }} "
            ></span>
            <input
              type="input"
              class="dropdown-toggle form-control form-select mb-0 border-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              autocomplete="nope"
              placeholder=""
              formControlName="mobileNumberCode"
              id="mobileNumberCode"
              appScrollToDirective
              scrollToTarget="scroll-to-gb"
              readonly
            />

            <ul class="dropdown-menu phone-code_dropdown">
              <li class="d-flex empty-value" (click)="onChangeMobileCode('')">
                <span class="dropdown-item"
                  >{{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.CHANGE_NUMBER.COUNTRY_CODE" | translate
                  }}</span
                >
              </li>
              <li
                class="d-flex dropdown-item"
                (click)="onChangeMobileCode(phoneCode.code)"
                *ngFor="let phoneCode of countryCodes"
              >
                <span
                  id="scroll-to-{{ phoneCode.code.toLowerCase() }}"
                  class="flag-icon flag-icon-{{ phoneCode.code.toLowerCase() }}"
                ></span>

                <span class="country-name">{{ phoneCode.countryName }}</span>
                <span class="country-phone-code">{{ phoneCode.phoneCode }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xxl-7 col-lg-9 col-md-9 col-sm-9 col-8 overflow-hidden">
          <input
            type="text"
            class="form-control border-0 mobile-number"
            autocomplete="nope"
            id="inputMobileNumber"
            placeholder="Cell phone number"
            formControlName="mobileNumber"
          />
        </div>
      </div>
    </div>
    <p
      class="error-message mb-0 mt-3"
      *ngIf="!mobileNumberCodeControl.value && mobileNumberControl.value"
    >
      {{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.CHANGE_NUMBER.COUNTRY_CODE_2" | translate }}
    </p>
    <p
      class="error-message mb-0 mt-3"
      *ngIf="mobileNumberControl.errors?.['pattern'] && mobileNumberControl.dirty"
    >
      {{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.CHANGE_NUMBER.CELL_NUMBER" | translate }}
    </p>
    <p
      class="error-message mb-0 mt-3"
      *ngIf="mobileNumberCodeControl.value && !mobileNumberControl.value"
    >
      {{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.CHANGE_NUMBER.CELL_NUMBER_2" | translate }}
    </p>
    <p class="error-message existing-number mb-0 mt-3" *ngIf="mobileNumberExists">
      {{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.CHANGE_NUMBER.CELL_NUMBER_3" | translate }}
    </p>
    <p class="message">
      {{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.CHANGE_NUMBER.ACCOUNT_RECOVERY" | translate }}
    </p>
  </div>
</form>

<button
  type="submit"
  (click)="onUpdateMobileNumber()"
  [disabled]="disableSubmitBtn"
  class="btn basic w-100 text-uppercase border-0 mt-3 mb-3 d-inline-flex justify-content-center align-items-center"
>
  <span class="text"
    >{{ "MOBILE_NUMBER.MOBILE_UPDATE_MODAL.CHANGE_NUMBER.UPDATE_NUMBER" | translate }}
  </span>
</button>
