<div class="modal-dialog" tabindex="-1">
  <div class="modal-content">
    <div class="modal-body">
      <div class="image-upload w-100 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <img class="img-fluid upload-avatar" src="/assets/icons/svg/upload-avatar-icon.svg" />
          <span class="title" *ngIf="!isImageSelected"
            >{{ "IMAGE_UPLOADER.SELECT_IMAGE" | translate }}</span
          >
          <span class="title" *ngIf="isImageSelected && !updateBannerImage"
            >{{ "IMAGE_UPLOADER.UPLOAD_AVATAR" | translate }}</span
          >
          <span class="title" *ngIf="isImageSelected && updateBannerImage"
            >{{ "IMAGE_UPLOADER.UPLOAD_BANNER" | translate }}</span
          >
        </div>
        <a type="button" data-bs-dismiss="modal" (click)="close()">
          <img class="svg-icon" src="/assets/icons/svg/close.svg" />
        </a>
      </div>
      <div
        *ngIf="!isImageSelected && !showUnsplashImages"
        class="no-image w-100 d-flex flex-column align-items-center dropzone"
        (drop)="handleDrop($event)"
        (dragover)="allowDrop($event)"
      >
        <img class="img-fluid no-image-bg" src="/assets/icons/svg/upload-avatar-bg1.svg" />
        <span class="instructions-1">{{ "IMAGE_UPLOADER.INSTRUCTIONS_1" | translate }}</span>
        <span class="instructions-2">{{ "IMAGE_UPLOADER.INSTRUCTIONS_2" | translate }}</span>
        <div class="d-sm-flex flex-row w-100 justify-content-between button-container">
          <div class="browse-button-div">
            <label
              for="file-upload"
              class="border-gradient file-upload-avatar d-sm-flex flex-row justify-content-center align-items-center"
            >
              <span class="file-upload-avatar-browse"
                >{{ "IMAGE_UPLOADER.BROWSE_FILES" | translate }}</span
              >
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".jpg,.png,.gif"
              (change)="fileChangeEvent($event)"
            />
          </div>
          <button
            id="choose-unsplash"
            type="button"
            class="btn btn-outline-dark text-uppercase choose-unsplash-btn"
            (click)="loadUnsplashPhotos()"
          >
            {{ "IMAGE_UPLOADER.SELECT_UNSPLASH" | translate }}
          </button>
        </div>
      </div>

      <!-- Show unsplash photos -->
      <div *ngIf="!isImageSelected && showUnsplashImages" class="images-wrapper">
        <div class="search-term d-flex">
          <app-svg-icon class="icon-search" [icon]="Icon.SEARCH"></app-svg-icon>
          <input
            id="search-value"
            type="text"
            class="input-field me-2"
            placeholder="{{ 'IMAGE_UPLOADER.SEARCH_IMAGES' | translate }}"
            #term
            [value]="searchValue"
            (keyup.enter)="searchUnsplashPhotos(term.value)"
          />
          <button
            id="search-unsplash-photos"
            class="btn border-gradient search-btn text-uppercase"
            type="button"
            (click)="searchUnsplashPhotos(term.value)"
          >
            {{ "IMAGE_UPLOADER.SEARCH" | translate }}
          </button>
        </div>

        <div class="image-gallery">
          <div
            class="image-card"
            *ngFor="let image of unsplashImages; let i = index"
            [ngClass]="{'image-card-selected': selectedImageIndex === i}"
          >
            <label>
              <img
                [src]="image.urls?.thumb"
                alt="{{ image.alt_description }}"
                (click)="selectUnsplashPhoto(image, i)"
              />
              <div class="check-icon" *ngIf="selectedImageIndex === i">
                <img class="icon-active-status" src="/assets/icons/svg/active-status.svg" />
              </div>
            </label>
          </div>
        </div>
        <p class="text-center no-results" *ngIf="!unsplashImages.length">
          {{ "IMAGE_UPLOADER.NO_IMAGES" | translate }}
        </p>
      </div>

      <!-- Show Cropped screen -->
      <div *ngIf="isImageSelected" class="row">
        <div class="text-center col-md-8">
          <h5>{{ "IMAGE_UPLOADER.CROP_IMAGE" | translate }}</h5>
          <image-cropper
            #cropper
            [imageChangedEvent]="imageChangedEvent"
            [imageFile]="imageFile"
            [imageURL]="imageURL"
            [maintainAspectRatio]="true"
            [maintainAspectRatio]="true"
            [aspectRatio]="updateBannerImage ? 16 / 9 : 1 / 1"
            [resizeToWidth]="800"
            [imageQuality]="100"
            format="jpeg"
            (imageCropped)="imageCropped($event)"
            [roundCropper]="!updateBannerImage"
          ></image-cropper>
        </div>
        <div class="text-center col-md-4">
          <h5>{{ "IMAGE_UPLOADER.PREVIEW" | translate }}</h5>
          <img [class.circle-img]="!updateBannerImage" [src]="croppedImage" />
        </div>
      </div>
    </div>

    <!-- Footer buttons -->
    <div
      class="modal-footer d-flex justify-content-between"
      *ngIf="isImageSelected || showUnsplashImages"
    >
      <div
        class="d-flex align-items-center back-btn"
        id="back-to-browse"
        (click)="setInitialValue()"
      >
        <div class="h-100 p-0 me-1">
          <app-svg-icon class="icon-back" [icon]="Icon.BACK_TO_LOGIN"></app-svg-icon>
        </div>
        <p class="text-uppercase">{{ "IMAGE_UPLOADER.BROWSE" | translate }}</p>
      </div>
      <div>
        <button
          id="close"
          type="button"
          class="btn text-uppercase border-0"
          data-bs-dismiss="modal"
          (click)="close()"
        >
          {{ "IMAGE_UPLOADER.CANCEL" | translate }}
        </button>
        <button
          *ngIf="!showUnsplashImages"
          id="upload"
          type="button"
          class="btn basic text-uppercase border-0"
          data-bs-dismiss="modal"
          (click)="upload()"
        >
          {{ "IMAGE_UPLOADER.SAVE" | translate }}
        </button>
        <button
          *ngIf="showUnsplashImages"
          id="upload"
          type="button"
          class="btn basic text-uppercase border-0"
          (click)="chooseUnsplashPhoto()"
        >
          {{ "IMAGE_UPLOADER.SELECT" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
