import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { APP_ROUTES } from '@core/constants';
import { MaintenanceService } from '../maintenance.service';

@Injectable()
export class MaintenanceGuard implements CanActivate {
  constructor(private maintenanceService: MaintenanceService, private router: Router) {}

  canActivate(): boolean {
    if (this.maintenanceService.isMaintenanceMode()) {
      return true;
    } else {
      this.router.navigate([APP_ROUTES.BASE]);
      return false;
    }
  }
}
