import { Component, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {
  get currentVersion() {
    return environment.version;
  }

  get currentYear() {
    return new Date().getUTCFullYear();
  }
  constructor() {}

  ngOnInit() {}
}
