import { AbstractControl, ValidationErrors } from '@angular/forms';

export function upperCaseValidator(control: AbstractControl): ValidationErrors | null {
  const upperCase = /[A-Z]/;

  if (control.value && !upperCase.test(control.value)) {
    return { invalidUpperCase: true };
  }
  return null;
}

export function lowerCaseValidator(control: AbstractControl): ValidationErrors | null {
  const lowerCase = /[a-z]/;

  if (control.value && !lowerCase.test(control.value)) {
    return { invalidLowerCase: true };
  }
  return null;
}

export function numberDigitValidator(control: AbstractControl): ValidationErrors | null {
  const numberDigit = /[0-9]/;

  if (control.value && !numberDigit.test(control.value)) {
    return { invalidNumberDigit: true };
  }
  return null;
}

export function specialCharacterValidator(control: AbstractControl): ValidationErrors | null {
  const specialCharacter = /[^A-Za-z0-9]/;

  if (control.value && !specialCharacter.test(control.value)) {
    return { invalidSpecialCharacter: true };
  }
  return null;
}
