export enum Icon {
  ABOUT_ME = 'about-me',
  ACCESSIBILITY = 'accessibility',
  ACCOUNT_SETTINGS = 'account-settings',
  ACTIVE_STATUS = 'active-status',
  ARROW = 'arrow',
  BACK_TO_LOGIN = 'back-to-login',
  BACKGROUND = 'background',
  BELL = 'bell',
  BILLING_ICON = 'billing-icon',
  CANDY_MENU = 'candy-menu',
  CAUTION_CIRCLE = 'caution-circle',
  CHECK_AMBER_ICON = 'check-amber-icon',
  CHECK_GREEN_ICON = 'check-green-icon',
  CHECK_RED_ICON = 'check-red-icon',
  CLEAR_ICON = 'clear-icon',
  CLOSE = 'close',
  COUNTRY_DROPDOWN = 'country-dropdown',
  DASHBOARD_BACKGROUND_MOBILE = 'dashboard-background-mobile',
  DASHBOARD_BACKGROUND = 'dashboard-background',
  DASHBOARD_CONTAINER_MOBILE = 'dashboard-container-mobile',
  DASHBOARD_CONTAINER_OLD = 'dashboard-container-old',
  DASHBOARD_CONTAINER = 'dashboard-container',
  DASHBOARD_ICON = 'dashboard-icon',
  DASHBOARD_MYVVID_LOGO_MOBILE = 'dashboard-myvvid-logo-mobile',
  DASHBOARD_MYVVID_LOGO = 'dashboard-myvvid-logo',
  ENVELOP_OPEN = 'envelop-open',
  EYE_ICON = 'eye-icon',
  GOOGLE_LOGO = 'google-logo',
  GREEN_DOT = 'green-dot',
  HAMBURGER_MENU = 'hamburger-menu',
  IA_LOGO_CANDY = 'ia-logo-candy',
  IA_LOGO = 'ia-logo',
  INTEREST_ICON = 'interest-icon',
  INVISIBLE_ICON = 'invisible-icon',
  IT_LOGO_CANDY = 'it-logo-candy',
  IT_LOGO = 'it-logo',
  KEY_ICON = 'key-icon',
  LICENSE_ICON = 'license-icon',
  LINKEDIN_BUTTON = 'linkedin-button',
  LINKEDIN_ICON = 'linkedin-icon',
  LINKEDIN_LOGO = 'linkedin-logo',
  LOCATION = 'location',
  LOCK_ICON = 'lock-icon',
  MAIN_VVID_LOGO = 'main-vvid-logo',
  MAP_LOGO_OLD = 'map-logo-old',
  MAP_LOGO = 'map-logo',
  MAP = 'map',
  MY_PROFILE = 'my-profile',
  MYVVID_LEFT_NAVI_ICON_COLLAPSED = 'myvvid-left-navi-icon-collapsed',
  MYVVID_LEFT_NAVI_ICON = 'myvvid-left-navi-icon',
  MYVVID_LOGO = 'myvvid-logo',
  MYVVID_IMG = 'myvvid_img',
  ORCID_LOGO = 'orcid-logo',
  ORGANISATION_ICON = 'organisation-icon',
  ORGANIZATION = 'organization',
  PASSWORD_ICON_2 = 'password-icon-2',
  PASSWORD_ICON = 'password-icon',
  PENCIL_ICON_2 = 'pencil-icon-2',
  PENCIL_ICON = 'pencil-icon',
  POP_UP_IMAGE = 'pop-up-image',
  PRODUCTS_ICON = 'products-icon',
  PROFILE_BANNER = 'profile-banner',
  PROFILE_ICON_2 = 'profile-icon-2',
  PROFILE_ICON = 'profile-icon',
  QUESTION = 'question',
  SEARCH = 'search',
  SELECT_INTEREST = 'select-interest',
  SETTING_SYNC = 'setting-sync',
  SETTINGS_INSIGHT = 'settings-insight',
  SETTINGS = 'settings',
  SIGN_IN_LEFT_SIDE = 'sign-in-left-side',
  SIGN_IN_VV_LOGO = 'sign-in-vv-logo',
  SOCIAL_GRAPHIC = 'social-graphic',
  SOCIAL_ICON = 'social-icon',
  SOCIAL_NETWORKS = 'social-networks',
  STEP_TEMPLATE = 'step-template',
  THROW_AWAY = 'throw-away',
  TI_LOGO_CANDY = 'ti-logo-candy',
  TI_LOGO = 'ti-logo',
  TICK = 'tick',
  TOAST_ERROR = 'toast-error',
  TOAST_SUCCESS = 'toast-success',
  TOAST_WARNING = 'toast-warning',
  UNION_ICON = 'union-icon',
  UPLOAD_AVATAR_BG1 = 'upload-avatar-bg1',
  UPLOAD_AVATAR_ICON = 'upload-avatar-icon',
  USER_ICON_CONFIG = 'user-icon-config',
  USER_ICON = 'user-icon',
  USER_JOB_ICON = 'user-job-icon',
  USER_PASSWORD = 'user-password',
  VISIBLE_ICON = 'visible-icon',
  VV_ID_LOGO = 'vv-id-logo',
  VV_ID_MAP = 'vv-id-map',
  VV_ID_TI_LOGO = 'vv-id-ti-logo',
  VV_LOGO_WHITE = 'vv-logo-white',
  X_AMBER_ICON = 'x-amber-icon',
  X_GRAY_ICON = 'x-gray-icon',
  X_GREEN_ICON = 'x-green-icon',
  X_RED_ICON = 'x-red-icon'
}
