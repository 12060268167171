import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { MaintenanceComponent } from './maintenance.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [SharedModule, RouterModule, TranslateModule],
  declarations: [MaintenanceComponent]
})
export class MaintenanceModule {}
