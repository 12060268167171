import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-message-pop-up',
  templateUrl: './notification-message-pop-up.component.html',
  styleUrls: ['./notification-message-pop-up.component.scss']
})
export class NotificationMessagePopUpComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
