import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfanityService {
  private _profanityListArr: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  get getProfanityArr(): Observable<string[]> {
    return this._profanityListArr.asObservable();
  }

  set setProfanityArr(value: string[]) {
    this._profanityListArr.next(value);
  }

  constructor(private http: HttpClient) {}

  getProfanityList(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/get-profanity-list`);
  }
}
