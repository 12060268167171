<div class="d-flex justify-content-center error-page-container">
  <div class="row error-wrapper d-flex justify-content-center">
    <div class="vvid-logo"></div>
    <div class="text-center message-wrapper col-lg-10 col-md-10">
      <p>{{ "ERROR_PAGE.ERROR_MESSAGE" | translate}}</p>
      <span *ngIf="!isSSOError">{{ error }}</span>
      <div *ngIf="isSSOError">
        <p class="mb-0 sso-error">{{ "ERROR_PAGE.NO_SSO_ERROR_MESSAGE_1" | translate}}</p>
        <p class="mb-0 sso-error">{{ "ERROR_PAGE.NO_SSO_ERROR_MESSAGE_2" | translate}}</p>
      </div>
    </div>
    <div class="buttons d-flex justify-content-center">
      <button
        id="btnBack"
        type="submit"
        class="btn basic text-uppercase back-btn border-0 d-inline-flex justify-content-center align-items-center"
        [routerLink]="'/' + routerLink"
      >
        <span class="text">{{ buttonLabel }}</span>
      </button>
    </div>
  </div>
</div>
