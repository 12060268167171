import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import {
  Auth,
  CognitoUser,
  CognitoHostedUIIdentityProvider,
  SignUpParams
} from '@aws-amplify/auth';
import { Amplify, Hub } from '@aws-amplify/core';

import { BehaviorSubject, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { environment } from '@env';
import { AUTH_KEYS, APP_ROUTES, AUTH_STORAGE_KEYS, ERROR_MESSAGES } from '@core/constants';

import {
  CognitoUserModel,
  UserModel,
  IAccount,
  IAuthFlow,
  IApiResponse,
  IAuthCode
} from '@core/models';

import {
  AUTH_EVENT,
  CognitoErrorTypes,
  CognitoUserAttributes,
  CognitoVerificationAttributes
} from '@core/enums';

import { AuthHelpers } from './auth.helpers';
import { IAmplifyAuth, IAppClient } from '@core/models';
import { WINDOW } from '@core/providers';
import { AccountService, AccountVerificationService } from '../account';
import { AppConfig } from 'src/app/app.config';
import { ToastService } from '../toast';
import { getErrorMessageByActionType } from '@core/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  _accessToken!: string;
  userVerified: boolean = false;

  qsClientId?: string | null;
  qsRedirectUri?: string | null;
  queryStringParams: URLSearchParams;

  qsAuthorizationCode?: string | null;
  qsClientState?: string | null;

  _hasRedirection = false;

  private stop$ = new Subject<void>();

  private _config: { Auth: IAmplifyAuth };

  private _cognitoUser: BehaviorSubject<CognitoUserModel | null> =
    new BehaviorSubject<CognitoUserModel | null>(null);

  private _userAccount: BehaviorSubject<IAccount | null> = new BehaviorSubject<IAccount | null>(
    null
  );

  get userAccount(): IAccount | null {
    return this._userAccount.value;
  }

  set userAccount(value: IAccount | null) {
    this._userAccount.next(value);
  }

  get cognitoUser(): CognitoUserModel | null {
    return this._cognitoUser.value;
  }

  get user(): UserModel | undefined {
    if (!this.cognitoUser) {
      return undefined;
    }

    const {
      sub: id,
      email,
      identities: identities,
      'custom:region': region,
      'custom:termsAccepted': termsAccepted,
      phone_number_verified: phone_number_verified
    } = this.cognitoUser.attributes;

    return { id, email, identities, region, termsAccepted, phone_number_verified };
  }

  /** @description Account attributes/claims */
  get attributes() {
    return this.cognitoUser?.attributes;
  }

  /** @description Account JWT Id Token */
  get idToken(): string | undefined {
    return this.cognitoUser?.getSignInUserSession()?.getIdToken().getJwtToken();
  }

  /** @description Account JWT Access Token */
  get accessToken(): string | undefined {
    return this.cognitoUser?.getSignInUserSession()?.getAccessToken().getJwtToken();
  }

  /** @description Account Refresh Token */
  get refreshToken(): string | undefined {
    return this.cognitoUser?.getSignInUserSession()?.getRefreshToken().getToken();
  }

  get isAuthenticated(): boolean {
    return !!(this.cognitoUser && this.cognitoUser.getSignInUserSession()?.isValid());
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private config: AppConfig,
    private authHelpers: AuthHelpers,
    @Inject(WINDOW) window: Window,
    private accountVerificationService: AccountVerificationService,
    private accountService: AccountService,
    private toastService: ToastService
  ) {
    this.queryStringParams = new URLSearchParams(window.location.search);
    this.qsRedirectUri = this.queryStringParams.get(AUTH_KEYS.REDIRECT_URI);
    this.qsClientId = this.queryStringParams.get(AUTH_KEYS.CLIENT_ID);
    this.qsAuthorizationCode = this.queryStringParams.get(AUTH_KEYS.AUTHORIZATION_CODE);
    this.qsClientState = this.queryStringParams.get(AUTH_KEYS.STATE);

    this._config = environment.awsCognitoConfig.amplify;

    if (this.qsClientId && this.qsRedirectUri) {
      this._hasRedirection = true;
      this._config.Auth.userPoolWebClientId = this.qsClientId;
    }

    // auto redirect
    const currentURL = new URL(window.location.href);
    const fullUrl = `${currentURL.origin}${currentURL.pathname}`;
    const hasAutoRedirect = fullUrl.includes(APP_ROUTES.AUTOREDIRECT);

    if (hasAutoRedirect) {
      const redirectSignIn = this._config.Auth.oauth.redirectSignIn;
      const redirectSignOut = this._config.Auth.oauth.redirectSignOut;
      this._config.Auth.oauth.redirectSignIn = `${redirectSignIn}/autoredirect`;
      this._config.Auth.oauth.redirectSignOut = `${redirectSignOut}/autoredirect`;
    }

    // Amplify.Logger.LOG_LEVEL = 'DEBUG';
    Amplify.configure(this._config);

    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      this.hubListener(event, data);
    });

    if (!this.qsClientId) {
      /* Get the user on creation of this service if it has already been logged in
      User has to have status confirmed in cognito in order to retrieve auth session */
      this.loadExistingAuthSession();
    }
  }

  async hubListener(event: string, data: any) {
    if (event === AUTH_EVENT.SIGN_IN) {
      const flow: IAuthFlow = this.setAuthFlow();

      // get the current user session
      this._cognitoUser.next(data);

      // SSO Login
      if (data.authenticationFlowType === 'USER_PASSWORD_AUTH') {
        await this.currentAuthenticatedUserforSSO();
      }

      this.setTokens(flow, data);
      this.setCustomAttributes();
      this.authHelpers.setCookies(flow);

      /* Loading account details to have access to the account in the other components.
      Here will be called only on sign in */
      this.loadAccountProfileDetails();

      // handle pre verfication of account before redirection to a product
      if (!this.user?.termsAccepted) {
        this.router.navigate([APP_ROUTES.DASHBOARD], { queryParamsHandling: 'merge' });
        return;
      }

      this.handleAuthorizationContext(flow);
    }

    if (event === AUTH_EVENT.SIGN_OUT) {
      this.authHelpers.eraseCookie(AUTH_KEYS.ID_TOKEN);
      this.authHelpers.eraseCookie(AUTH_KEYS.ACCESS_TOKEN);
      this.authHelpers.eraseCookie(AUTH_KEYS.REFRESH_TOKEN);
    }
  }

  async loadExistingAuthSession(): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this._cognitoUser.next(user);
      this.setCustomAttributes();

      // <!-- Disable phone verification -->
      if (
        !this.cognitoUser?.attributes.identities &&
        !this.cognitoUser?.attributes.email_verified
      ) {
        this.signOut(false);
      }

      /* Loading account details have access to the account in the other components.
      Here will be called only on reloading of the page */
      this.loadAccountProfileDetails();
    } catch (e) {
      console.warn('loadAuthSessionError: ', e);
      return;
    }
  }

  async currentAuthenticatedUserforSSO(): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this._cognitoUser.next(user);
    } catch (e) {
      console.warn('loadAuthSessionError: ', e);
      return;
    }
  }

  /* Update local storage session user attribute */
  async updateUserExistingAuthSessionAttribute(attributesObject: {
    [key in CognitoUserAttributes]?: string | boolean;
  }) {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, attributesObject);
  }

  async signUp(user: IAccount): Promise<{ error: boolean; code?: CognitoErrorTypes } | void> {
    const signUpParams = this.userToCognitoSignUpParams(user);
    this.userAccount = user;

    try {
      const sendVerificationMessage$ = this.accountVerificationService.sendVerificationMessage(
        user.username,
        user.password,
        CognitoVerificationAttributes.Email
      );

      await Auth.signUp(signUpParams);

      const response = await lastValueFrom(sendVerificationMessage$);

      this._accessToken = response.accessToken;

      this.router.navigate([APP_ROUTES.CONFIRM]);
    } catch (error: any) {
      switch (error.code) {
        case CognitoErrorTypes.NetworkError:
          this.router.navigate([APP_ROUTES.ERROR], {
            state: {
              error: ERROR_MESSAGES.SYSTEM_ERROR,
              route: APP_ROUTES.CREATE_ACCOUNT,
              networkError: true
            }
          });
          break;
        case CognitoErrorTypes.UserLambdaValidation:
          return { error: true, code: CognitoErrorTypes.UsernameExists };
      }

      return { error: true };
    }
  }

  async confirmSignUp(code: string): Promise<{ error: boolean }> {
    const verifyAttribute$ = this.accountVerificationService.verifyAttribute(
      this._accessToken || '',
      this.idToken || '',
      code,
      CognitoVerificationAttributes.Email
    );

    const response = await lastValueFrom(verifyAttribute$);

    if (response.error) {
      return { error: true };
    }

    if (this.userAccount) {
      await Auth.signIn(this.userAccount?.email, this.userAccount?.password);
    } else {
      this.router.navigate([APP_ROUTES.LOGIN]);
    }

    return { error: false };
  }

  /**
   *
   * @param attribute default attribute is email
   */
  async resendSignUp(attribute?: CognitoVerificationAttributes): Promise<{ error: boolean }> {
    try {
      const resendVerificationMessage$ = this.accountVerificationService.resendVerificationMessage(
        attribute || CognitoVerificationAttributes.Email,
        this._accessToken || this.accessToken
      );

      const response = await lastValueFrom(resendVerificationMessage$);

      if (response.error) {
        return { error: true };
      }

      return { error: false };
    } catch (error) {
      console.warn(error);

      return { error: true };
    }
  }

  async signIn(
    email: string,
    password: string
  ): Promise<{ error: boolean; code?: CognitoErrorTypes; message?: string } | void> {
    try {
      const checkAttributesVerification$ =
        this.accountVerificationService.checkAttributeVerification(
          email,
          password,
          CognitoVerificationAttributes.Email
        );

      const checkAttributeResponse = await lastValueFrom(checkAttributesVerification$);

      if (checkAttributeResponse.verified) {
        const signInResult = await Auth.signIn(email, password);

        return { error: false };
        // TODO : cover edge case for reset password/force change password challenge name
      } else if (checkAttributeResponse.error) {
        return {
          error: true,
          code: CognitoErrorTypes.NotAuthorized
        };
      } else {
        this._accessToken = checkAttributeResponse.accessToken;

        const sendVerificationMessage$ = this.accountVerificationService.sendVerificationMessage(
          email,
          password,
          CognitoVerificationAttributes.Email,
          this._accessToken
        );
        const sendVerificationMessageResponse = await lastValueFrom(sendVerificationMessage$);

        if (!sendVerificationMessageResponse.error) {
          this.router.navigate([APP_ROUTES.CONFIRM]);
        } else {
          this.router.navigate([APP_ROUTES.CONFIRM], {
            state: {
              error: true
            }
          });
        }
      }
    } catch (error: any) {
      this.handleSignInError(error);

      return {
        error: true,
        code: error.code,
        message: error.message
      };
    }
  }

  cognitoSignIn(): void {
    Auth.federatedSignIn();
  }

  async federatedSignIn(provider: CognitoHostedUIIdentityProvider | string): Promise<void> {
    try {
      const promise$ = this.http.get<boolean>(`/identity-provider/${provider}`);
      const exist = await lastValueFrom(promise$);

      if (exist) {
        this.handleSingleSignOn(provider);
      } else {
        this.router.navigate([APP_ROUTES.ERROR], {
          state: {
            error: ERROR_MESSAGES.SYSTEM_ERROR,
            route: APP_ROUTES.BASE,
            networkError: true
          }
        });
      }
    } catch (error) {
      console.error('Could not find identity provider. Server response: ' + error);

      this.router.navigate([APP_ROUTES.ERROR], {
        state: {
          error: ERROR_MESSAGES.NO_SSO_ERROR,
          route: APP_ROUTES.BASE,
          networkError: true
        }
      });
    }
  }

  /**
   *
   * @param redirect defines if you want to redirect to the login page
   */
  async signOut(redirect: boolean): Promise<void> {
    try {
      await Auth.signOut();
      this._cognitoUser.next(null);

      if (redirect) {
        this.router.navigate([APP_ROUTES.BASE], { queryParamsHandling: 'merge' });
      }
    } catch (e) {
      console.warn('Error Logging out', e);
      return;
    }
  }

  async forgotPassword(email: string): Promise<void> {
    try {
      environment.awsCognitoConfig.forgotPasswordUsername = email;
      await Auth.forgotPassword(email);
      // eslint-disable-next-line no-empty
    } catch (error: any) {
      switch (error.code) {
        case CognitoErrorTypes.NetworkError:
          this.router.navigate([APP_ROUTES.ERROR], {
            state: {
              error: ERROR_MESSAGES.SYSTEM_ERROR,
              route: APP_ROUTES.RESET_PASSWORD,
              networkError: true
            }
          });
          break;
      }
    }
  }

  async changePassword(code: string, newPassword: string): Promise<{ error: boolean }> {
    try {
      await Auth.forgotPasswordSubmit(
        environment.awsCognitoConfig.forgotPasswordUsername,
        code,
        newPassword
      );

      return {
        error: false
      };
    } catch (error) {
      console.warn(error);
      return {
        error: true
      };
    }
  }

  async refreshSession(): Promise<boolean> {
    if (!this.cognitoUser) {
      return false;
    }

    const token = this.cognitoUser.getSignInUserSession()?.getRefreshToken();

    if (token) {
      this.cognitoUser.refreshSession(token, () => {
        console.log('Refresh token complete!');
      });
    }

    return true;
  }

  async resendCode(): Promise<{ error: boolean }> {
    try {
      /* current cognito configuration does not allow us to use resend code apis in cognito
      hence we use for resend amplifyAuth.forgotPassword again */
      await Auth.forgotPassword(environment.awsCognitoConfig.forgotPasswordUsername);

      return {
        error: false
      };
    } catch (error) {
      console.warn(error);
      return {
        error: true
      };
    }
  }

  async userChangePassword(
    user: CognitoUser,
    oldPassword: string,
    newPassword: string
  ): Promise<{ error: boolean; code?: CognitoErrorTypes }> {
    try {
      await Auth.changePassword(user, oldPassword, newPassword);

      return {
        error: false
      };
    } catch (error: any) {
      console.warn(error);

      this.toastService.addAll([
        {
          severity: 'error',
          text: getErrorMessageByActionType({ password: oldPassword })
        }
      ]);

      return {
        error: true,
        code: error.code
      };
    }
  }

  handleAuthorizationContext(flow?: IAuthFlow) {
    if (!flow) {
      flow = this.setAuthFlow();
      this.setTokens(flow);
    }

    if (flow.authorization_code && flow.redirect_uri) {
      this.handlePKCEFlow(flow);
    } else if (flow.redirect_uri) {
      this.handleImplicitFlow(flow);
    } else {
      this.handleLocalFlow(this.cognitoUser);
    }
  }

  handlePKCEFlow(flow: IAuthFlow): boolean {
    const codes: IAuthCode = {
      authorization_code: flow.authorization_code,
      id_token: flow.id_token,
      access_token: flow.access_token,
      refresh_token: flow.refresh_token
    };

    let params = new URLSearchParams();

    if (flow.authorization_code) {
      params.append('code', flow.authorization_code);
    }

    if (flow.state) {
      params.append('state', flow.state);
    }

    this.http.post<IApiResponse>('/storage', codes).subscribe({
      next: result => {
        this.authHelpers.redirectTo(flow.redirect_uri, params);
      },
      error: error => {
        console.error('Could not store codes. Server response: ' + JSON.stringify(error));
      }
    });

    return true;
  }

  async handleImplicitFlow(flow: IAuthFlow): Promise<boolean> {
    const params = new URLSearchParams();

    if (flow.id_token) {
      params.append(AUTH_KEYS.ID_TOKEN, flow.id_token);
    }

    if (flow.state) {
      params.append(AUTH_KEYS.STATE, flow.state);
    }

    this.authHelpers.redirectTo(flow.redirect_uri, params);

    return true;
  }

  handleLocalFlow(user: CognitoUserModel | null): void {
    // Sign in directly to myvvid (not from redirect from client as part of oauth2 flow)
    this._cognitoUser.next(user);

    this.router.navigate([APP_ROUTES.DASHBOARD], {
      state: { userId: this.cognitoUser?.attributes?.sub }
    });
  }

  handleSingleSignOn(provider: string): void {
    const domain = this._config.Auth.oauth.domain;

    // NOTE : there is an issue when logging in directly to myvvid,
    // code_verifier produced from authorize endpoint is missing,
    // so we need to use the federatedSignIn to take care of this.
    if (!this._hasRedirection) {
      this._config.Auth.oauth.domain = `auth.${domain}`;
      Auth.configure(this._config);
      Auth.federatedSignIn({ customProvider: provider });

      return;
    }

    const params = new URLSearchParams({
      identity_provider: provider,
      redirect_uri: this._config.Auth.oauth.redirectSignIn,
      response_type: this._config.Auth.oauth.responseType,
      client_id: this._config.Auth.userPoolWebClientId,
      scope: this._config.Auth.oauth.scope.join(' ')
    });

    let client: IAppClient | undefined;

    if (this.qsClientId) {
      params.set(AUTH_KEYS.CLIENT_ID, this.qsClientId);
      client = this.config.getClient(this.qsClientId);
    }

    if (client) {
      params.set(AUTH_KEYS.RESPONSE_TYPE, client.response_type);
    }

    if (this.qsRedirectUri) {
      params.set(AUTH_KEYS.REDIRECT_URI, this.qsRedirectUri);
    }

    if (this.qsClientState) {
      params.append(AUTH_KEYS.STATE, this.qsClientState);
    }

    this.authHelpers.redirectTo(`https://auth.${domain}/oauth2/authorize`, params);
  }

  private userToCognitoSignUpParams(user: IAccount): SignUpParams {
    return {
      username: user.username,
      password: user.password,
      attributes: {
        phone_number: user.mobileNumber,
        email: user.email,
        name: user.firstName,
        family_name: user.lastName
      },
      clientMetadata: {
        location: user.location,
        mobileNumber: user.mobileNumber,
        organizationId: user.organizationId,
        organizationName: user.organizationName,
        isNewOrganization: user.isNewOrganization,
        jobTitle: user.jobTitle
      }
    };
  }

  private handleSignInError(error: any): void {
    switch (error.code) {
      case CognitoErrorTypes.NetworkError:
        this.router.navigate([APP_ROUTES.ERROR], {
          state: {
            error: ERROR_MESSAGES.SYSTEM_ERROR,
            route: APP_ROUTES.BASE,
            networkError: true
          }
        });
        break;
    }
  }

  private async setCustomAttributes() {
    const token = this.authHelpers.decodeToken(this.idToken);
    const termsAccepted = JSON.parse(token['custom:termsAccepted']);
    const phoneNumberVerified = JSON.parse(token.phone_number_verified);

    if (this.cognitoUser) {
      this.cognitoUser.attributes['custom:termsAccepted'] = termsAccepted;
      this.cognitoUser.attributes.phone_number_verified = phoneNumberVerified;
    }
  }

  private setAuthFlow(): IAuthFlow {
    const flow: IAuthFlow = {
      client_id: '',
      redirect_uri: null,
      state: null,
      authorization_code: null,
      id_token: '',
      access_token: '',
      refresh_token: ''
    };

    /*
     * For a local sign in the redirect_uri/authorization_code will be in the query string params
     */
    if (this.qsRedirectUri) {
      flow.redirect_uri = this.qsRedirectUri;
      flow.authorization_code = this.queryStringParams.get(AUTH_KEYS.AUTHORIZATION_CODE);
      flow.state = this.queryStringParams.get(AUTH_KEYS.STATE);
    } else {
      /*
       * For a federated sign in the redirect_uri/authorization_code will be in the local storage
       */
      flow.redirect_uri = localStorage.getItem(AUTH_STORAGE_KEYS.CLIENT_REDIRECT_URI);
      flow.authorization_code = localStorage.getItem(AUTH_KEYS.AUTHORIZATION_CODE);
      flow.state = localStorage.getItem(AUTH_STORAGE_KEYS.CLIENT_STATE);

      this.removeStoredAuthFlow();
    }

    return flow;
  }

  private setTokens(flow: IAuthFlow, data?: any) {
    if (data) {
      // get the current user session
      const authInfo = data.signInUserSession;
      flow.id_token = authInfo.idToken.jwtToken;
      flow.access_token = authInfo.accessToken.jwtToken;
      flow.refresh_token = authInfo.refreshToken.token;
    } else {
      flow.id_token = this.idToken;
      flow.access_token = this.accessToken;
      flow.refresh_token = this.refreshToken;
    }
  }

  private removeStoredAuthFlow(): void {
    localStorage.removeItem(AUTH_STORAGE_KEYS.CLIENT_REDIRECT_URI);
    localStorage.removeItem(AUTH_KEYS.AUTHORIZATION_CODE);
    localStorage.removeItem(AUTH_STORAGE_KEYS.CLIENT_STATE);
  }

  private loadAccountProfileDetails(): void {
    this.accountService
      .loadAccountProfileDetails()
      .pipe(takeUntil(this.stop$))
      .subscribe(value => {
        this.accountService.setUserDetails = value;
      });
  }
}
