<div class="d-flex justify-content-center">
  <div class="row main-container">
    <div class="col-lg-6 col-md-0 col-sm-0 d-none d-lg-block left-side">
      <app-left-side></app-left-side>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 my-auto">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
