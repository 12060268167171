import jwt_decode from 'jwt-decode';
import { IAuthFlow } from '../../models/auth.model';

export class AuthHelpers {
  jwtDecode = jwt_decode;

  setCookies(flow: IAuthFlow) {
    // Set the ID and access token cookies for fast SSO
    if (flow.id_token && flow.access_token && flow.refresh_token) {
      this.setTokenCookie('id_token', flow.id_token);
      this.setTokenCookie('access_token', flow.access_token);

      /*
       * Set the refresh token cookie. Refresh token cannot be parsed for an an expiry so use the access token to get an expiry.
       * Although the refresh token has a different (longer) expiry than the access token, this is for the purpose of fast SSO,
       * so the refresh token cookie will get set again when the id or access token cookie expires
       */
      this.setRefreshTokenCookie(flow.refresh_token, flow.access_token);
    } else {
      console.error('Inconsistent application state: Tokens missing from current session');
      return;
    }
  }

  setTokenCookie(type: string, token: string) {
    const tokenDecoded: object = this.jwtDecode(token);
    const tokenExpiry = tokenDecoded['exp'];
    this.setCookie(type, token, tokenExpiry);
  }

  setRefreshTokenCookie(refreshToken: string, accessToken: string) {
    // Use expiry of access token to set the refresh token cookie
    const tokenDecoded: object = this.jwtDecode(accessToken);
    const tokenExpiry = tokenDecoded['exp'];
    this.setCookie('refresh_token', refreshToken, tokenExpiry);
  }

  getCookie(name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  setCookie(name: string, value: string, expiry: number) {
    let expires = '';
    if (expiry) {
      const date = new Date(expiry * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + value + expires + '; path=/';
  }

  eraseCookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  decodeToken(token: any): any {
    return this.jwtDecode(token);
  }

  redirectTo(url: string | null, params?: URLSearchParams | string): void {
    let redirectUrl = url;

    if (params) {
      redirectUrl = `${url}/?${params}`;
    }

    return window.location.replace(`${redirectUrl}`);
  }

  urlOpener(url: string, self: string = '_self'): Promise<Window> {
    const windowProxy = window.open(url, self);
    if (windowProxy) {
      return Promise.resolve(windowProxy);
    } else {
      return Promise.reject();
    }
  }
}
