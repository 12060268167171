import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, Inject, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '@env';
import { AppConfig } from './app.config';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { httpTranslationLoader } from '@shared/classes/http-translate-loader';
import { PrivateShellModule, PublicShellModule } from './layouts';
import { CoreModule } from '@core';
import { LanguageEnum } from '@core/enums';
import { MaintenanceModule } from '@modules/maintenance/maintenance.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    CoreModule,
    SharedModule,
    PrivateShellModule,
    PublicShellModule,
    MaintenanceModule,
    Angulartics2Module.forRoot({
      gst: environment.gstConfig
    }),
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem('locale') || LanguageEnum.EN_GB,
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslationLoader,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => async () => await config.initialize(),
      deps: [AppConfig],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(TranslateService) private translateService: TranslateService) {
    this.translateService.addLangs(Object.values(LanguageEnum));
    this.translateService.use(LanguageEnum.EN_GB);
  }
}
