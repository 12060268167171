export const AUTH_EVENT = {
  SIGN_IN: 'signIn',
  SIGN_OUT: 'signOut',
  INIT: 'init'
};

export const AUTH_IDP = {
  GOOGLE_IDP: 'Google',
  ORCID_IDP: 'ORCID',
  LINKED_IN_IDP: 'Auth0LinkedIn'
};
