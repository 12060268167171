import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@core/services';
import { APP_ROUTES } from '@core/constants';
import { MaintenanceService } from '@core/services/maintenance';

@Injectable()
/**
 * @description Prevents access to routes when users is already logged in
 * @redirectsTo products-dashboard component
 * */
export class AnonymousGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private maintenanceService: MaintenanceService
  ) {}

  canActivate(): boolean {
    return this.returnAuthorizedOrRedirect();
  }

  canActivateChild(): boolean {
    return this.returnAuthorizedOrRedirect();
  }

  private returnAuthorizedOrRedirect(): boolean {
    // Check if the application is in maintenance mode
    if (this.maintenanceService.isMaintenanceMode()) {
      this.router.navigate(['/maintenance']);
      return false;
    }

    if (this.auth.isAuthenticated) {
      this.router.navigate([APP_ROUTES.DASHBOARD]);
      return false;
    }

    return true;
  }
}
